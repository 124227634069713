// import EventsModal from "../components/EventsModal/EventsModal";
import PinComponent from "../components/PinModal/PinModal";
import {
  deleteUsers,
  updateMultipleUsers,
} from "../redux/users/actions";
import { requestToServer } from "../utils/commonUtils";

export function getActions(
  showSuccessAlert,
  showErrorAlert,
  showRedModal,
  showBlueModal,
  showCustomModal,
  usersList,
  navigate,
  dispatch,
  fetchUserList,
  selectedEvent
) {
  const selectedUsers = usersList.filter((user) => user.isSelected);
  const selectedUsersLength = selectedUsers.length;
  const actions = [
    {
      place: 1,
      displayValue: "Delete",
      disabled: selectedUsersLength === 0,
      onClick() {
        showRedModal({
          title: `Delete User${selectedUsersLength > 1 ? "s" : ""}?`,
          modalText: `Are you sure you want to delete ${
            selectedUsersLength > 1 ? "these users" : "this user"
          }?`,
          buttonText: "Delete",
          warningText: `If you delete ${
            selectedUsersLength > 1 ? "these users" : "this user"
          }, related information will be deleted.`,
          async onClick() {
            try {
              const selectedUsersId = selectedUsers.map((user) => user.id);
              await dispatch(deleteUsers(selectedUsersId));
              fetchUserList();
              const message = `${
                selectedUsersLength > 1 ? "Users" : "User"
              } deleted successfully!`;
              showSuccessAlert(message);
            } catch (error) {
              console.error(error);
              showErrorAlert(error.message);
            }
          },
        });
      },
    },
    {
      place: 2,
      displayValue: "Edit",
      disabled: !(selectedUsersLength === 1),
      onClick() {
        navigate("edit-user", { state: { user: selectedUsers[0] } });
      },
    },
    {
      place: 3,
      displayValue: "Deactivate",
      disabled: selectedUsersLength === 0,
      onClick() {
        showRedModal({
          title: `Deactivate User${selectedUsersLength > 1 ? "s" : ""}?`,
          modalText: `Are you sure you want to deactivate ${
            selectedUsersLength > 1 ? "these users" : "this user"
          }?`,
          buttonText: "Deactivate",

          async onClick() {
            try {
              const requestBody = selectedUsers.map((user) => ({
                id: user.id,
                status: 0,
              }));
              await dispatch(updateMultipleUsers(requestBody));
              fetchUserList();
              const message = `${
                selectedUsersLength > 1 ? "Users" : "User"
              } deactivated successfully!`;
              showSuccessAlert(message);
            } catch (error) {
              console.error(error);
              showErrorAlert(error.message);
            }
          },
        });
      },
    },
    {
      place: 4,
      displayValue: "Activate",
      disabled: selectedUsersLength === 0,
      onClick() {
        showBlueModal({
          title: `Activate User${selectedUsersLength > 1 ? "s" : ""}?`,
          modalText: `Are you sure you want to activate ${
            selectedUsersLength > 1 ? "these users" : "this user"
          }?`,
          buttonText: "Activate",
          async onClick() {
            try {
              const requestBody = selectedUsers.map((user) => ({
                id: user.id,
                status: 1,
              }));
              await dispatch(updateMultipleUsers(requestBody));
              fetchUserList();
              const message = `${
                selectedUsersLength > 1 ? "Users" : "User"
              } activated successfully!`;
              showSuccessAlert(message);
            } catch (error) {
              console.error(error);
              showErrorAlert(error.message);
            }
          },
        });
      },
    },
    // {
    //   place: 5,
    //   displayValue: "Attach Event",
    //   disabled: !(selectedUsersLength === 1),
    //   onClick() {
    //     showCustomModal({
    //       title: "Attach Event? ",
    //       customComponent: <EventsModal selectedUsers={selectedUsers} />,
    //     });
    //   },
    // },
    {
      place: 6,
      displayValue: "Reset Pin",
      disabled: !(selectedUsersLength === 1),
      onClick() {
        showCustomModal({
          title: `Reset Pin (${selectedUsers[0].name})`,
          customComponent: <PinComponent selectedUser={selectedUsers[0]} />,
        });
      },
    },
    {
      place: 7,
      displayValue: "Detach",
      disabled: !(selectedEvent && selectedUsersLength > 0),
      onClick() {
        showRedModal({
          title: `Detach User${selectedUsersLength > 1 ? "s" : ""}?`,
          modalText: `Are you sure you want to detach ${
            selectedUsersLength > 1 ? "these users" : "this user"
          }?`,
          buttonText: "Detach",
          async onClick() {
            try {
              await requestToServer("/detachUsers", {
                method: "POST",
                body: JSON.stringify({
                  id: selectedUsers.map((user) => user.id),
                  eventId: selectedEvent,
                }),
              });
              fetchUserList();
              const message = `${
                selectedUsersLength > 1 ? "Users" : "User"
              } detached successfully!`;
              showSuccessAlert(message);
            } catch (error) {
              console.error(error);
              showErrorAlert(error.message);
            }
          },
        });
      },
    },
    {
      place: 8,
      displayValue: "Make Coordinator",
      disabled: !(selectedEvent && selectedUsersLength > 0),
      onClick() {
        showBlueModal({
          title: `Make Coordinator${selectedUsersLength > 1 ? "s" : ""}?`,
          modalText: `Are you sure you want to add ${
            selectedUsersLength > 1 ? "these coordinators" : "this coordinator"
          }?`,
          buttonText: "Confirm",
          async onClick() {
            try {
              await requestToServer("/coordinators", {
                method: "PATCH",
                body: JSON.stringify({
                  coordinatorIds: selectedUsers.map((user) => user.id),
                  eventId: selectedEvent,
                }),
              });
              const message = `Added ${
                selectedUsersLength > 1 ? "coordinators" : "coordinator"
              } successfully!`;
              showSuccessAlert(message);
            } catch (error) {
              console.error(error);
              showErrorAlert(error.message);
            }
          },
        });
      },
    },
  ];
  return actions;
}
