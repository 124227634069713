import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestToServer, toUseDemoData } from "../../utils/commonUtils";
import { events } from "../../demo-data/events-demo-data";
import eventsFormatterForUI from "../../formatters/eventsFormatter";

// get events action
export const getEvents = createAsyncThunk(
  "events/getEvents",
  async (filterObject, thunkApi) => {
    if (toUseDemoData()) {
      return {
        data: events,
      };
    } else {
      try {
        const queryParams = new URLSearchParams(filterObject).toString();
        const eventsAPIData = await requestToServer(`/events?${queryParams}`);
        return {
          data: eventsAPIData.map((data) => eventsFormatterForUI(data)),
        };
      } catch (e) {
        return thunkApi.rejectWithValue({ message: e.message });
      }
    }
  }
);

// get particular event
export const getEventById = createAsyncThunk(
  "events/getEventById",
  async ({ eventId, queryObject }) => {
    if (toUseDemoData()) {
      return events[0];
    } else {
      const queryParams = new URLSearchParams(queryObject).toString();
      const eventAPIData = await requestToServer(
        `/events/${eventId}?${queryParams}`
      );
      return eventsFormatterForUI(eventAPIData);
    }
  }
);

// create event action
export const createEvent = createAsyncThunk(
  "events/createEvent",
  async (eventInfo, thunkApi) => {
    if (toUseDemoData()) {
      return null;
    } else {
      try {
        const eventsAPIData = await requestToServer("/events", {
          method: "POST",
          body: JSON.stringify(eventInfo),
        });
        return {
          data: eventsAPIData,
        };
      } catch (e) {
        return thunkApi.rejectWithValue(e.message);
      }
    }
  }
);

// update event action
export const updateEvent = createAsyncThunk(
  "events/updateEvent",
  async (eventInfo, thunkApi) => {
    if (toUseDemoData()) {
      return null;
    } else {
      if (eventInfo.id) {
        try {
          const eventsAPIData = await requestToServer(
            `/events/${eventInfo.id}`,
            {
              method: "PATCH",
              body: JSON.stringify(eventInfo),
            }
          );
          return {
            data: eventsAPIData,
          };
        } catch (e) {
          return thunkApi.rejectWithValue(e.message);
        }
      } else {
        throw new Error("Couldn't find event to update!");
      }
    }
  }
);

// delete event action
export const deleteEvents = createAsyncThunk(
  "events/deleteEvents",
  async (eventIds, thunkApi) => {
    if (toUseDemoData()) {
      return null;
    } else {
      try {
        const eventsAPIdata = await requestToServer("/deleteEvents", {
          method: "POST",
          body: JSON.stringify({ ids: eventIds }),
        });
        return { data: eventsAPIdata };
      } catch (e) {
        return thunkApi.rejectWithValue(e.message);
      }
    }
  }
);
