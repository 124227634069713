import "./addUserForm.css";
import React, { useState, useRef } from "react";
import DragDropFileUpload from "../DragDropFileUpload/DragDropFileUpload";
import DropdownWithSearch from "../EventsDropdown/EventsDropdown";
import useClickOutside from "../../utils/useClickOutside";
import { useSelector } from "react-redux";
import { eventsSelector } from "../../redux/events/selectors";
import { allUsersSelector, loadingSelector } from "../../redux/users/selector";
import Icon from "../Icon/Icon";
import { Tooltip } from "react-tooltip";
import LineWithText from "../LineWithText/LineWithText";
import MultiSelectDropdownWithSearch from "../MultiSelectDropdownWithSearch/MultiSelectDropdownWithSearch";
const AddUserForm = ({
  userDetails,
  fileInputElement,
  handleInputChange,
  handleFileUpload,
  handleSubmit,
  handleResetForm,
  handleEventSelection,
  handleUserSelection,
  selectedEventId,
  selectedUserId,
  showSingleUserForm,
  setShowSingleUserForm,
  userList,
  setUserList,
}) => {
  // closing events dropdown when clicked outside of it.
  const eventsDropdownRef = useRef(null);
  useClickOutside(eventsDropdownRef, () => setShowEventsDropdown(false));

  const uploadFileLoading = useSelector(loadingSelector);
  const eventsList = useSelector(eventsSelector);
  const selectedEvent = eventsList.find(
    (event) => event.id === selectedEventId
  );
  const eventsInputValue = selectedEvent && selectedEvent.name;
  const anyUserSelected = userList.some(user => user.isSelected)

  const [showEventsDropdown, setShowEventsDropdown] = useState(false);

  // functions for disabling buttons of this page
  function shouldDisableSubmitButton() {
    if (showSingleUserForm) {
      return !userDetails.mobile || !userDetails.name;
    } else {
      return uploadFileLoading || (!userDetails.files && !anyUserSelected);
    }
  }

  function shouldDisableResetButton() {
    return !(
      selectedEventId || Object.values(userDetails).some((detail) => detail) || anyUserSelected
    );
  }

  return (
    <div className="card col-sm-12 col-md-8 col-xl-6 px-3">
      <div className="card-title">Add User</div>
      <div className="add-sub-form px-1 px-md-5 d-flex flex-column gap-4">
        <div className="form-label">
          Event
          <div
            ref={eventsDropdownRef}
            id="events-selection"
            className="position-relative"
          >
            <div
              className="filter-dropdown form-select"
              onClick={() =>
                setShowEventsDropdown(
                  (prevShowEventsDropdown) => !prevShowEventsDropdown
                )
              }
            >
              <div className="border-0 cursor-pointer flex-grow-1 overflow-hidden">
                {eventsInputValue || "Select Event"}
              </div>
            </div>
            {showEventsDropdown && (
              <DropdownWithSearch
                itemList={eventsList}
                selectedItemId={selectedEventId}
                handleItemSelection={handleEventSelection}
                setShowItemsDropdown={setShowEventsDropdown}
                searchPlaceHolder="Enter Event Name"
                listHeading="Event Name"
              />
            )}
          </div>
        </div>
        <div>
          <nav>
            <ul className="tabs">
              <li
                className={showSingleUserForm ? "active" : "inactive"}
                onClick={() => setShowSingleUserForm(true)}
              >
                Add Single
              </li>
              <li
                id="add-multiple-user-tab"
                className={!showSingleUserForm ? "active" : "inactive"}
                onClick={() => setShowSingleUserForm(false)}
              >
                Add Multiple
              </li>
            </ul>
          </nav>
          <div className="tab-form d-flex flex-column justify-content-center px-3 py-3">
            {showSingleUserForm ? (
              <AddSingleUserForm
                userDetails={userDetails}
                handleInputChange={handleInputChange}
                selectedUserId={selectedUserId}
                handleUserSelection={handleUserSelection}
              />
            ) : (
              <>
                {/* User multi select dropdown (visible when no files are selected) */}
                {!userDetails.files && (
                  <MultiSelectDropdownWithSearch
                    itemList={userList}
                    setItemList={setUserList}
                    placeholder="Select Existing Users"
                    searchPlaceHolder="Enter User Name"
                    listHeading="User Name"
                  />
                )}
                {!anyUserSelected && !userDetails.files && (
                  <LineWithText text="OR" />
                )}
                {/* Drag and drop file upload component (visible when no user are selected) */}
                {!anyUserSelected && (
                  <DragDropFileUpload
                    handleFileUpload={handleFileUpload}
                    fileInputElement={fileInputElement}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-evenly my-4">
        <button
          className="button primary-button rounded"
          disabled={shouldDisableSubmitButton()}
          onClick={handleSubmit}
        >
          Submit
        </button>
        <button
          className="button secondary-button rounded"
          disabled={shouldDisableResetButton()}
          onClick={handleResetForm}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

function AddSingleUserForm({
  userDetails,
  handleInputChange,
  selectedUserId,
  handleUserSelection,
}) {
  // functionality to close the dropdown on outside click
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setShowUserDropdown(false));

  // user list from redux store
  const userList = useSelector(allUsersSelector);

  const [showUserDropdown, setShowUserDropdown] = useState(false);
  return (
    <div id="add-single-user-form" className="d-flex flex-column gap-3 w-100">
      <div className="position-relative" ref={dropdownRef}>
        <label className="form-label m-0" htmlFor="name">
          Name
        </label>
        <div className="d-flex gap-1">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={userDetails.name}
            onChange={handleInputChange}
            readOnly={selectedUserId}
          />
          <Icon
            fontSize="1.25rem"
            className="border border-1 p-1 rounded"
            onClick={() =>
              setShowUserDropdown(
                (prevShowEventsDropdown) => !prevShowEventsDropdown
              )
            }
          >
            <i
              className="bi bi-person-lines-fill"
              data-tooltip-id="user-list"
              data-tooltip-content="User List"
              data-tooltip-place="top"
            ></i>
          </Icon>
          <Tooltip id="user-list" />
        </div>
        {showUserDropdown && (
          <DropdownWithSearch
            itemList={userList}
            selectedItemId={selectedUserId}
            setShowItemsDropdown={setShowUserDropdown}
            handleItemSelection={handleUserSelection}
            searchPlaceHolder="Enter User Name"
            listHeading="User Name"
            style={{ height: "16rem" }}
          />
        )}
      </div>
      <label className="form-label">
        Mobile Number
        <input
          type="number"
          className="form-control"
          name="mobile"
          value={userDetails.mobile}
          onChange={handleInputChange}
          readOnly={selectedUserId}
        />
      </label>
      <label id="isAdmin" className="form-label d-flex flex-row gap-3 align-self-end">
        Admin
        <input
          type="checkbox"
          className="form-check-input cursor-pointer"
          name="isAdmin"
          checked={userDetails.isAdmin}
          onChange={handleInputChange}
        />
      </label>
    </div>
  );
}
export default AddUserForm;
