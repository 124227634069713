import React, { useState, useEffect } from "react";
import "./listEvent.css"
import EventsFilters from "../../components/Filters/Filters";
import EventTable from "../../components/EventTable/EventTable";
import { useNavigate } from "react-router";
import { useAlert } from "../../context/AlertContext";
import { useModal } from "../../context/ModalContext";
import { getEventActions } from "../../constants/EventsConstants";
import { useSelector, useDispatch } from "react-redux";
import { eventsSelector, errorSelector } from "../../redux/events/selectors";
import PageHeadingWithOptions from "../../components/PageHeadingWithOptions/PageHeadingWithOptions";

const ListEvent = () => {
  // state from redux
  const events = useSelector(eventsSelector);
  const error = useSelector(errorSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const { showBlueModal, showRedModal, showCustomModal } = useModal();

  const [eventsList, setEventsList] = useState([]);
  const [areAllEventsSelected, setAreAllEventsSelected] = useState(false);
  const [eventFilters, setEventFilters] = useState({
    isVisible: false,
    nameFilter: "",
    statusFilter: "",
  });


  const isFilterActive = eventFilters.nameFilter || eventFilters.statusFilter;

  const eventActions = getEventActions(
    showRedModal,
    showBlueModal,
    showCustomModal,
    showSuccessAlert,
    showErrorAlert,
    eventsList,
    navigate,
    dispatch
  );

  useEffect(() => {
    if (events && events.length > 0) {
      setEventsList(events.map((event) => ({ ...event, isSelected: false })));
    }
  }, [events]);

  useEffect(() => {
    if (error) {
      showErrorAlert(error.message);
    }
  }, [error, showErrorAlert]);

  useEffect(() => {
    if (eventsList.length > 0) {
      setAreAllEventsSelected(eventsList.every((event) => event.isSelected));
    }
  }, [eventsList]);

  // function returning filtered events list according to input in nameFilter
  function filteredEventsList() {
    let filteredEvents = eventsList;
    if (eventFilters.nameFilter) {
      filteredEvents = filteredEvents.filter((event) =>
        event.name
          ?.toLowerCase()
          .includes(eventFilters.nameFilter.toLowerCase())
      );
    }
    if (eventFilters.statusFilter) {
      filteredEvents = filteredEvents.filter(
        (event) => event.status === eventFilters.statusFilter
      );
    }
    return filteredEvents;
  }

  // function for handling the change of single event selection checkbox
  function handleEventSelection(e, id) {
    setEventsList((prevEventsList) =>
      prevEventsList.map((event) =>
        event.id === id ? { ...event, isSelected: e.target.checked } : event
      )
    );
  }

  // function for handling the change of all events selection checkbox
  function handleAllEventsSelectionChange(e) {
    setAreAllEventsSelected(e.target.checked);
    setEventsList((prevEventsList) =>
      prevEventsList.map((event) => ({
        ...event,
        isSelected: e.target.checked,
      }))
    );
  }

  // function that handles change in filters
  function handleFilterChange(e) {
    setEventFilters((prevEventFilters) => ({
      ...prevEventFilters,
      [e.target.name]: e.target.value,
    }));
  }

  // function for clearing applied filters
  function clearFilter() {
    setEventFilters({ isVisible: false, nameFilter: "", statusFilter: "" });
  }

  
  // function to toggle filter visibility
  function toggleFilterVisibility() {
    setEventFilters((prevEventFilters) => ({
      ...prevEventFilters,
      isVisible: !prevEventFilters.isVisible,
    }));
  }

  return (
    <main id="main" className="main">
      <PageHeadingWithOptions
        pageHeading="Events / Event List"
        actions={eventActions}
        actionsTooltipText="Actions on selected events"
        filtersTooltipText="Filters"
        toggleFilterVisibility={toggleFilterVisibility}
        isFilterActive={isFilterActive}
        isFilterVisible={eventFilters.isVisible}
        filterComponent={
          <EventsFilters
            filters={eventFilters}
            handleFilterChange={handleFilterChange}
            clearFilter={clearFilter}
            nameFilterPlaceholder="Enter Event Name"
            statusOptions={[
              { status: "active", displayValue: "Active" },
              { status: "inactive", displayValue: "Inactive" },
            ]}
            showCloseButton={true}
          />
        }
      />

      <EventTable
        eventsList={filteredEventsList()}
        handleEventSelection={handleEventSelection}
        handleAllEventsSelectionChange={handleAllEventsSelectionChange}
        areAllEventsSelected={areAllEventsSelected}
      />
    </main>
  );
};

export default ListEvent;
