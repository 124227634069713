import React, { useState } from "react";
import "./editUser.css"
import EditUserForm from "../../components/EditUserForm/EditUserForm";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlert } from "../../context/AlertContext";
import { useDispatch } from "react-redux";
import { getAllUsers, updateUser } from "../../redux/users/actions";
import PageHeadingWithOptions from "../../components/PageHeadingWithOptions/PageHeadingWithOptions";

const EditUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state && location.state.user;
  const { showSuccessAlert, showErrorAlert } = useAlert();

  const [userDetails, setUserDetails] = useState({
    name: user.name,
    mobile: user.mobile,
  });

  // function that handles the change in inputs of form
  function handleInputChange(event) {
    const { name, value } = event.target;

    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [name]: value,
    }));
  }

  // update user functionality
  async function handleSubmit() {
    try {
      await dispatch(
        updateUser({
          id: user.id,
          name: userDetails.name,
          mobile: userDetails.mobile,
        })
      );
      dispatch(getAllUsers());
      showSuccessAlert("User Information updated successfully!");
    } catch (error) {
      console.error(error);
      showErrorAlert(error.message);
    }
  }

  function handleCloseClick() {
    navigate(-1);
  }

  return (
    <main id="main" className="main">
      <PageHeadingWithOptions pageHeading="Users / Edit User" />
      <div className="container row justify-content-center">
        <EditUserForm
          userDetails={userDetails}
          handleInputChange={handleInputChange}
          updateUser={handleSubmit}
          handleCloseClick={handleCloseClick}
        />
      </div>
    </main>
  );
};

export default EditUser;
