import React from "react";
import "./messageTable.css"
import Icon from "../Icon/Icon";
import { dateInUIFormat } from "../../utils/dateUtils";
import { capitalizeString } from "../../utils/commonUtils";
import { useMessagePreview } from "../../context/MessagePreviewContext";
import { useXmppService } from "../../context/xmppContext";

const MessageTable = (props) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">S.No.</th>
          <th scope="col">Message Name</th>
          <th scope="col">Created Date</th>
          <th scope="col">Status</th>
          <th scope="col" className="text-center">
            Preview
          </th>
          <th className="text-center">
            <input
              type="checkbox"
              className="form-check-input cursor-pointer"
              onChange={props.handleAllMessagesSelectionChange}
              checked={props.areAllMessagesSelected}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {props.messageList &&
          props.messageList.map((message, i) => (
            <MessageEntry
              key={message.id}
              index={i}
              {...message}
              selectedTab={props.selectedTab}
              handleMessageSelectionChange={props.handleMessageSelectionChange}
              previewPrimaryButtonText={props.previewPrimaryButtonText}
            />
          ))}
      </tbody>
    </table>
  );
};

const MessageEntry = (props) => {
  const { showMessagePreview, setMessagePreview } = useMessagePreview();
  const { sendMessage } = useXmppService();
  return (
    <tr>
      <td>{props.index + 1}</td>
      <td>{props.name}</td>
      <td>{props.createdDate && dateInUIFormat(props.createdDate)}</td>
      <td>{props.status && capitalizeString(props.status)}</td>
      <td className="text-center">
        <Icon
          fontSize="1rem"
          onClick={() =>
            showMessagePreview({
              primaryButtonText: props.previewPrimaryButtonText,
              primaryButtonOnClick: () => {
                setMessagePreview({ isVisible: false });
                sendMessage(props.selectedTab.jid, props.content, "chat")
              },
              message: {
                name: props.name,
                content: props.content,
              },
            })
          }
        >
          <i className="bi bi-eye-fill"></i>
        </Icon>
      </td>
      <td className="text-center">
        <input
          type="checkbox"
          className="form-check-input cursor-pointer"
          onChange={(e) => props.handleMessageSelectionChange(e, props.id)}
          checked={props.isSelected}
        />
      </td>
    </tr>
  );
};

export default MessageTable;
