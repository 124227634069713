// steps for tour according to path
export const tourSteps = {
  "/dashboard": [
    { selector: "#logo", content: <h3>Udghosh</h3> },
    {
      selector: "#dashboard-tab",
      content: (
        <p>
          <strong>Dashboard</strong> will give you an overview of users and
          events analytics.
        </p>
      ),
    },
    {
      selector: "#users-tab",
      content: (
        <p>
          <strong>Users</strong> tab lets manage your users.
        </p>
      ),
    },
    {
      selector: "#events-tab",
      content: (
        <p>
          <strong>Events</strong> tab lets you manage your events.
        </p>
      ),
    },
    {
      selector: "#messages-tab",
      content: (
        <p>
          <strong>Message Templates</strong> tab lets you manage your message
          templates.
        </p>
      ),
    },
    {
      selector: "#chat-tab",
      content: (
        <p>
          <strong>Chat</strong> tab let you chat with users in system.
        </p>
      ),
    },
    { selector: "#events-card", content: "Total number of events in system." },
    {
      selector: "#users-card",
      content: "Active and total number of users in system.",
    },
  ],
  "/dashboard/user-list": [
    {
      selector: null,
      content: (
        <p>
          This is <strong>User List</strong> page. Here you can manage your
          existing users.
        </p>
      ),
      position: "center",
      padding: 0,
    },
    {
      selector: ".bi-pencil-square",
      content: ({ isHighlightingObserved }) =>
        isHighlightingObserved
          ? "Actions for selected Users"
          : " Click on this icon to see the actions you can take on the selected users.",
      position: "left",
      mutationObservables: ["#actions-dropdown"],
      highlightedSelectors: ["#actions-dropdown"],
    },
    {
      selector: ".bi-funnel-fill",
      content: ({ isHighlightingObserved }) =>
        isHighlightingObserved
          ? "Apply filters from here."
          : "Click here to apply filters.",
      mutationObservables: ["#filter-container"],
      highlightedSelectors: ["#filter-container"],
    },
    {
      selector: ".bi-chat-dots-fill",
      content: "Click on this icon will take you to chat screen.",
    },
  ],
  "/dashboard/add-user": [
    {
      selector: null,
      content: (
        <p>
          This is <strong>Add User</strong> page. Here you can add single or
          multiple users to events.
        </p>
      ),
      position: "center",
      padding: 0,
    },
    {
      selector: "#events-selection",
      content: ({ isHighlightingObserved }) =>
        isHighlightingObserved
          ? "Select the events in which you want to add users."
          : "Open this dropdown to select event in which you want to add users.",
      mutationObservables: [".events-dropdown"],
      highlightedSelectors: [".events-dropdown"],
    },
    {
      selector: "#add-single-user-form",
      content: "Fill out the information of user.",
    },
    {
      selector: "#isAdmin",
      content: "You can select the role of user.",
    },
    {
      selector: "#add-multiple-user-tab",
      content: (isHighlightingObserved) =>
        isHighlightingObserved
          ? "Upload a file with users information to create multiple users."
          : "Click here to create multiple users at once.",
      mutationObservables: ["#add-multiple-user-form"],
      highlightedSelectors: ["#add-multiple-user-form"],
    },
  ],
  "/dashboard/user-list/edit-user": [
    {
      selector: null,
      content: (
        <p>
          This is <strong>Edit User</strong> page. You can edit the information
          of particular user here.
        </p>
      ),
      position: "center",
      padding: 0,
    },
  ],
  "/dashboard/list-event": [
    {
      selector: null,
      content: (
        <p>
          This is <strong>Event List</strong> page. Here you can manage your
          existing events.
        </p>
      ),
      position: "center",
      padding: 0,
    },
    {
      selector: ".bi-pencil-square",
      content: ({ isHighlightingObserved }) =>
        isHighlightingObserved
          ? "Actions for selected Events"
          : " Click on this icon to see the actions you can take on the selected events.",
      position: "left",
      mutationObservables: ["#actions-dropdown"],
      highlightedSelectors: ["#actions-dropdown"],
    },
    {
      selector: ".bi-funnel-fill",
      content: ({ isHighlightingObserved }) =>
        isHighlightingObserved
          ? "Apply filters from here."
          : "Click here to apply filters.",
      mutationObservables: ["#filter-container"],
      highlightedSelectors: ["#filter-container"],
    },
    {
      selector: ".bi-people-fill",
      content: "Click on this icon to see users attached to this event.",
    },
    {
      selector: ".bi-three-dots-vertical",
      content:
        "Click on this icon to see message templates attached to this event.",
    },
    {
      selector: ".bi-megaphone-fill",
      content: "Click on this icon to see announcements of this event.",
    },
  ],
  "/dashboard/add-event": [
    {
      selector: null,
      content: (
        <p>
          This is <strong>Add Event</strong> page. This page allows you to add
          events to your system and attach message templates to those events.
        </p>
      ),
      position: "center",
      padding: 0,
    },
    {
      selector: "#event-form",
      content: "Fill out the event information here.",
    },
    {
      selector: "#message-attach",
      content: "Attach message templates to the event from here.",
    },
    {
      selector: ".bi-eye-fill",
      content:
        "Click on this icon to see the preview of this message template.",
    },
  ],
  "/dashboard/edit-event": [
    {
      selector: null,
      content: (
        <p>
          This is <strong>Edit Event</strong> page. Here you can edit
          information of particular event.
        </p>
      ),
      position: "center",
      padding: 0,
    },
  ],
  "/dashboard/event-announcement": [
    {
      selector: null,
      content: (
        <p>
          This is <strong>Event Announcement</strong> page. Here you can select
          an event group and send messages to that group.
        </p>
      ),
      position: "center",
      padding: 0,
    },
    {
      selector: ".bi-paperclip",
      content:
        "Click on this icon to select documents, video, audio, images and message templates you want to send in this group.",
    },
  ],
  "/dashboard/message-list": [
    {
      selector: null,
      content: (
        <p>
          This is <strong>Message List</strong> page. Here you can manage your
          existing message templates.
        </p>
      ),
      position: "center",
      padding: 0,
    },
    {
      selector: ".bi-pencil-square",
      content: ({ isHighlightingObserved }) =>
        isHighlightingObserved
          ? "Actions for selected Message Templates"
          : " Click on this icon to see the actions you can take on the selected message templates.",
      position: "left",
      mutationObservables: ["#actions-dropdown"],
      highlightedSelectors: ["#actions-dropdown"],
    },
    {
      selector: ".bi-funnel-fill",
      content: ({ isHighlightingObserved }) =>
        isHighlightingObserved
          ? "Apply filters from here."
          : "Click here to apply filters.",
      mutationObservables: ["#filter-container"],
      highlightedSelectors: ["#filter-container"],
    },
    {
      selector: ".bi-eye-fill",
      content:
        "Click on this icon to see the preview of this message template.",
    },
  ],
  "/dashboard/new-message": [
    {
      selector: null,
      content: (
        <p>
          This is <strong>Add Template</strong> page. Here you can create
          message templates.
        </p>
      ),
      position: "center",
      padding: 0,
    },
  ],
  "/dashboard/edit-message": [
    {
      selector: null,
      content: (
        <p>
          This is <strong>Edit Template</strong> page. Here you can edit a
          particular message template.
        </p>
      ),
      position: "center",
      padding: 0,
    },
  ],
  "/dashboard/chat": [
    {
      selector: null,
      content: (
        <p>
          This is <strong>Chat</strong> page. This page allows you to chat with
          other users in system.
        </p>
      ),
      position: "center",
      padding: 0,
    },
    {
      selector: ".bi-paperclip",
      content:
        "Click on this icon to select documents, video, audio, images and message templates you want to send to this user.",
    },
  ],
};

// styles for tour
export const tourStyles = {
  badge: (base) => ({
    ...base,
    backgroundColor: "var(--home-secondary-bg)",
    color: "var(--clr-primary)",
  }),
  close: (base) => ({
    ...base,
    color: "var(--home-primary-bg)",
    top: "1rem",
    right: "1rem",
  }),
  popover: (base) => ({
    ...base,
    borderRadius: "0.5rem",
    "--reactour-accent": "var(--home-primary-bg)",
  }),
  highlightedArea: (base) => ({ ...base, padding: "50px" }),
};
