import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestToServer, toUseDemoData } from "../../utils/commonUtils";
import { filteredUsers, users } from "../../demo-data/users-demo-data";
import statusFormatter from "../../formatters/statusFormatter";

// get users with filter action
export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (filterObject, thunkApi) => {
    if (toUseDemoData()) {
      return {
        data: users,
      };
    } else {
      try {
        const queryParams = new URLSearchParams(filterObject).toString();
        const usersAPIData = await requestToServer(`/users?${queryParams}`);
        return {
          data: usersAPIData.map((user) => ({
            ...user,
            status: statusFormatter(user.status),
          })),
        };
      } catch (e) {
        return thunkApi.rejectWithValue(e.message);
      }
    }
  }
);

//  get all users action
export const getAllUsers = createAsyncThunk("users/getAllUsers", async () => {
  if (toUseDemoData()) {
    return users;
  } else {
    const usersAPIData = await requestToServer("/users");
    return usersAPIData.map((user) => ({
      ...user,
      status: statusFormatter(user.status),
    }));
  }
});

// getting users from event/{eventId}
export const getUsersFromEvent = createAsyncThunk(
  "users/getUsersFromEvent",
  async ({ eventId, queryObject }) => {
    if (toUseDemoData()) {
      return {
        data: filteredUsers,
      };
    } else {
      const queryParams = new URLSearchParams(queryObject).toString();
      const apiData = await requestToServer(
        `/events/${eventId}?${queryParams}`
      );
      return {
        data: apiData.users?.map((user) => ({
          ...user,
          status: statusFormatter(user.status),
        })) || [],
      };
    }
  }
);

// create user action
export const createUser = createAsyncThunk(
  "users/createUser",
  async (userInfo, thunkApi) => {
    if (toUseDemoData()) {
      return null;
    } else {
      try {
        const usersAPIData = await requestToServer("/users", {
          method: "POST",
          body: JSON.stringify(userInfo),
        });
        return { data: usersAPIData };
      } catch (e) {
        return thunkApi.rejectWithValue(e.message);
      }
    }
  }
);

// upload user list action
export const uploadUsersList = createAsyncThunk(
  "users/uploadUsersList",
  async (formData, thunkApi) => {
    if (toUseDemoData()) {
      return null;
    } else {
        /*
        We can't use the upper method because when we are trying to set Content-Type header manually to "multipart/form-data", we can't set boundary along with it which is necessary for server to parse it. Hence we are not setting any Content-Type header and browser is automatically setting the Content-Type along with its boundary.
        */

        // const usersAPIData = await requestToServer("/upload-users-list", {
        //   method: "POST",
        //   body: formData,
        //   headers: {
        //     "Content-Type": "multipart/form-data"
        //   }
        //   });
        //   return { data: usersAPIData };
        const storeState = thunkApi.getState();
        const token = storeState.login?.user?.token;
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/upload-users-list`,
          {
            method: "POST",
            headers: {
              "Authorization": `Bearer ${token}`
            },
            body: formData,
          }
        );
        if (response.ok) {
          const jsonResponse = await response.json();
          if (jsonResponse.status === 0 && !jsonResponse.error) {
            return {data: jsonResponse.data};
          } else {
            throw new Error(jsonResponse.error)
          }
        } else {
          throw new Error(response.statusText)
        }
    }
  }
);

// update user action
export const updateUser = createAsyncThunk(
  "users/editUser",
  async (userInfo, thunkApi) => {
    if (toUseDemoData()) {
      return null;
    } else {
      if (userInfo.id) {
        try {
          const usersAPIData = await requestToServer(`/users/${userInfo.id}`, {
            method: "PATCH",
            body: JSON.stringify(userInfo),
          });
          return { data: usersAPIData };
        } catch (e) {
          return thunkApi.rejectWithValue(e.message);
        }
      } else {
        throw new Error("Couldn't find event to update!")
      }
    }
  }
);

// update multiple users action
export const updateMultipleUsers = createAsyncThunk(
  "users/updateMultipleUsers",
  async (usersInfo, thunkApi) => {
    if (toUseDemoData()) {
      return null;
    } else {
      try {
        const usersAPIData = await requestToServer("/update-users", {
          method: "POST",
          body: JSON.stringify(usersInfo),
        });
        return { data: usersAPIData };
      } catch (e) {
        return thunkApi.rejectWithValue(e.message);
      }
    }
  }
);

// delete users action
export const deleteUsers = createAsyncThunk(
  "users/deleteUsers",
  async (userIds, thunkApi) => {
    if (toUseDemoData()) {
      return null;
    } else {
      try {
        const usersAPIData = await requestToServer("/deleteUsers", {
          method: "POST",
          body: JSON.stringify({ id: userIds }),
        });
        return { data: usersAPIData };
      } catch (e) {
        return thunkApi.rejectWithValue(e.message);
      }
    }
  }
);
