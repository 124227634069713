import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTour } from "@reactour/tour";
import { tourSteps } from "../constants/tourConstants";

export function useSetTourSteps() {
  const location = useLocation();
  const { setSteps, isOpen } = useTour();

  // getting steps that have selectors present in dom.
  function filteringSteps(steps) {
    const filteredSteps = steps.filter((step) => {
      if (step.selector === null) {
        return true;
      } else {
        const element = document.querySelector(step.selector);
        return element !== null;
      }
    });
    return filteredSteps;
  }

  useEffect(() => {
    let baseSteps = tourSteps[location.pathname] ?? [];
    isOpen && setSteps(filteringSteps(baseSteps));
  }, [isOpen, setSteps, location.pathname]);
}
