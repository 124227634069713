import { createSlice } from "@reduxjs/toolkit";
import { deleteMessage, getMessages, updateMessage } from "./actions";

const initialState = {
  messages: [],
  error: null,
};

export const messageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMessages.fulfilled, (state, action) => {
        state.messages = action.payload;
        state.error = null;
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(updateMessage.rejected, (_, action) => {
        throw new Error(action.error.message);
      })
      .addCase(deleteMessage.rejected, (_, action) => {
        throw new Error(action.error.message);
      });
  },
});

export default messageSlice.reducer;
