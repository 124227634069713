import { deleteEvents, getEvents, updateEvent } from "../redux/events/actions";

export const getEventActions = (
  showRedModal,
  showBlueModal,
  showCustomModal,
  showSuccessAlert,
  showErrorAlert,
  eventsList,
  navigate,
  dispatch
) => {
  const selectedEvents = eventsList.filter((event) => event.isSelected);
  const selectedEventsLength = selectedEvents.length;
  return [
    {
      place: 1,
      displayValue: "Delete",
      disabled: selectedEventsLength === 0,
      onClick() {
        showRedModal({
          title: `Delete Event${selectedEventsLength > 1 ? "s" : ""}?`,
          modalText: `Are you sure you want to delete ${
            selectedEventsLength > 1 ? "these events" : "this event"
          }?`,
          buttonText: "Delete",
          warningText: `If you delete ${
            selectedEventsLength > 1 ? "these events" : "this event"
          }, related information will be deleted.`,
          async onClick() {
            // delete events functionality
            try {
              const selectedEventsId = selectedEvents.map((event) => event.id);
              await dispatch(deleteEvents(selectedEventsId));
              dispatch(getEvents());
              const message = `${
                selectedEventsLength > 1 ? "Events" : "Event"
              } deleted successfully!`;
              showSuccessAlert(message);
            } catch (error) {
              console.error(error);
              showErrorAlert(error.message);
            }
          },
        });
      },
    },
    {
      place: 2,
      displayValue: "Edit",
      disabled: !(selectedEventsLength === 1),
      onClick() {
        navigate("/dashboard/edit-event", { state: { event: selectedEvents[0] } });
      },
    },
    {
      place: 3,
      displayValue: "Deactivate",
      disabled: selectedEventsLength === 0,
      onClick() {
        showRedModal({
          title: `Deactivate Event${selectedEventsLength > 1 ? "s" : ""}?`,
          modalText: `Are you sure you want to deactivate ${
            selectedEventsLength > 1 ? "these events" : "this event"
          }?`,
          buttonText: "Deactivate",
          async onClick() {
            // deactivate events functionality
            try {
              const deactivateRequests = selectedEvents.map((event) =>
                dispatch(updateEvent({ id: event.id, status: 0 }))
              );
              await Promise.all(deactivateRequests);
              dispatch(getEvents());
              const message = `${
                selectedEventsLength > 1 ? "Events" : "Event"
              } deactivated successfully!`;
              showSuccessAlert(message);
            } catch (error) {
              console.error(error);
              showErrorAlert(error.message);
            }
          },
        });
      },
    },
    {
      place: 4,
      displayValue: "Activate",
      disabled: selectedEventsLength === 0,
      onClick() {
        showBlueModal({
          title: `Activate Event${selectedEventsLength > 1 ? "s" : ""}?`,
          modalText: `Are you sure you want to activate ${
            selectedEventsLength > 1 ? "these events" : "this event"
          }?`,
          buttonText: "Activate",
          async onClick() {
            // activate events functionality
            try {
              const activateRequests = selectedEvents.map((event) =>
                dispatch(updateEvent({ id: event.id, status: 1 }))
              );
              await Promise.all(activateRequests);
              dispatch(getEvents());
              const message = `${
                selectedEventsLength > 1 ? "Events" : "Event"
              } activated successfully!`;
              showSuccessAlert(message);
            } catch (error) {
              console.error(error);
              showErrorAlert(error.message);
            }
          },
        });
      },
    },
    {
      place: 5,
      displayValue: "Attach User",
      disabled: !(selectedEventsLength === 1),
      onClick() {
        navigate("/dashboard/add-user", {
          state: { selectedEventId: selectedEvents[0].id },
        });
    },
    },
  ];
};
