import statusFormatter from "./statusFormatter";

export const messageFormatter = (message) => {
  const { subject, detail, ...restMessageInfo } = message;
  return {
    name: subject,
    content: detail,
    ...restMessageInfo,
    status: statusFormatter(restMessageInfo.status),
  };
};
