import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestToServer, toUseDemoData } from "../../utils/commonUtils";
import { dashboardStats } from "../../demo-data/dashboard-demo-data";

export const getDashboardStats = createAsyncThunk(
  "dashboardStats/getDashboardStats",
  async () => {
    if (toUseDemoData()) {
      return { data: dashboardStats };
    } else {
        const dashboardApiData = await requestToServer("/dashboard");
        return dashboardApiData;
    }
  }
);
