import "./home.css";
import { Link } from "react-router-dom";
import React from "react";
import Hero1 from "../../assets/images/Hero1.png";
import Hero2 from "../../assets/images/Hero2.png";
import Hero3 from "../../assets/images/Hero3.png";

function Home() {
  return (
    <main className="home-container">
      <HeroBanner1 />
      <HeroBanner2 />
      <HeroBanner3 />
    </main>
  );
}

export default Home;

function HeroBanner1() {
  return (
    <div className="hero-banner-1" style={{ padding: "110px 0px 40px" }}>
      <div className="container d-flex flex-column-reverse flex-lg-row align-items-stretch align-items-lg-start justify-content-between gap-5">
        <div className="col-lg-6 d-flex flex-column">
          <h1 className="fs-1 fw-bolder text-center text-lg-start">
            Welcome to Udghosh
          </h1>
          <h3 className="fs-3 text-center text-lg-start">
            Unite. Share. Engage.
          </h3>
          <p className="mt-4 text-justify">
            Udghosh is your go-to platform for creating dynamic, interactive
            groups tailored for colleges, corporations, and event organisers. We
            provide a seamless way to share information, coordinate activities,
            and foster communication within your community.
          </p>
          <Link
            to="/login"
            id="home-button"
            className="btn px-4 py-2 rounded-pill mt-5 align-self-center align-self-lg-start"
          >
            Sign in Now!
          </Link>
        </div>
        <div className="col-lg-6 d-flex justify-content-center align-items-center">
          <img
            style={{
              width: "60%",
            }}
            src={Hero1}
            alt="Hero banner"
          ></img>
        </div>
      </div>
    </div>
  );
}

function HeroBanner2() {
  return (
    <div className="hero-banner-2" style={{ padding: "40px 0px" }}>
      <div className="container d-flex align-items-center justify-content-center"></div>
      <div className="container d-flex flex-column align-items-start flex-lg-row justify-content-between gap-5 ">
        <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center rounded-3">
          <img style={{ width: "90%" }} src={Hero2} alt="Hero banner" />
          <h2 className="lh-1 fs-2 mb-2 mt-4 w-100 text-center text-lg-start">
            Our Mission
          </h2>
          <p className="text-justify mt-2">
            Udghosh aims to simplify group management and enhance communication
            within colleges, corporate teams, and event organizations. We strive
            to create an organized, efficient, and engaging environment where
            information is easily accessible and communication is seamless.
          </p>
        </div>
        <div className="d-flex flex-column text-justify">
          <h2 className="lh-1 fs-2 pb-2 text-center text-lg-start">Features</h2>
          <p className="mt-2">
            Create Groups: Easily set up groups for different departments,
            projects, or events within your organisation.
          </p>
          <p className="mt-2">
            Share Information: Admins can broadcast important updates,
            documents, and announcements to all group members effortlessly.
          </p>
          <p className="mt-2">
            Interactive Communication: Group members can send messages directly
            to admins, ensuring a smooth flow of information and feedback.
          </p>
          <p className="mt-2">
            Event Coordination: Perfect for event planning, Udghosh allows you
            to manage event details and share maps and schedules with attendees.
          </p>
          <p className="mt-2">
            Map Integration: Provide members with event locations and
            navigation, ensuring everyone knows where to go.
          </p>
          <button
            id="home-button"
            className="btn px-4 py-2 rounded-pill mt-5 align-self-center align-self-lg-end"
          >
            Sign up Now!
          </button>
        </div>
      </div>
    </div>
  );
}

function HeroBanner3() {
  return (
    <div className="hero-banner-3" style={{ padding: "40px 0px" }}>
      <div className="container d-flex flex-column-reverse flex-lg-row align-items-stretch align-items-lg-start justify-content-between gap-5">
        <div className="col-lg-6 text-justify">
          <h2 className="lh-1 fs-2 mb-2 mt-2 text-center text-lg-start">
            Join the Community
          </h2>
          <p className="mt-4">
            Ready to enhance your group's communication and coordination? Join
            Udghosh today and experience the future of group management.
          </p>
          <h2 className="lh-1 fs-2 mb-2 mt-4 text-center text-lg-start">
            Get Started
          </h2>
          <p className="mt-4">
            Sign Up: Create your free account and start organising your groups.
          </p>
          <p className="mt-2">
            Create Groups: Set up different groups for various purposes within
            your organisation.
          </p>
          <p className="mt-2">
            Share Info: Broadcast important updates and announcements to your
            group members.
          </p>
          <p className="mt-2">
            Engage: Receive and respond to messages from group members.
          </p>
          <p className="mt-2">
            Coordinate Events: Share event details and maps to keep everyone
            informed and on track.
          </p>
        </div>
        <div className="col-lg-6 d-flex justify-content-center align-items-center">
          <img
            style={{
              width: "60%",
            }}
            src={Hero3}
            alt="Hero banner"
          ></img>
        </div>
      </div>
    </div>
  );
}
