import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState({ isVisible: false });

  function showRedModal({
    title,
    modalText,
    buttonText,
    warningText,
    onClick,
  }) {
    setModal({
      isVisible: true,
      title,
      modalText,
      buttonText,
      warningText,
      onClick,
      dangerAction: true,
    });
  }
  function showBlueModal({
    title,
    modalText,
    buttonText,
    warningText,
    onClick,
  }) {
    setModal({
      isVisible: true,
      title,
      modalText,
      buttonText,
      warningText,
      onClick,
      dangerAction: false,
    });
  }

  function showCustomModal({ title, customComponent }) {
    setModal({
      isVisible: true,
      title,
      customComponent,
    });
  }

  return (
    <ModalContext.Provider
      value={{ modal, setModal, showRedModal, showBlueModal, showCustomModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};
