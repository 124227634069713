import { createSlice } from "@reduxjs/toolkit";
import { getDashboardStats } from "./actions";

const initialState = {
  dashboardStats: {},
  error: null,
};

const dashboardStatsSlice = createSlice({
  name: "dashboardStats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardStats.fulfilled, (state, action) => {
        state.error = null;
        state.dashboardStats = action.payload;
      })
      .addCase(getDashboardStats.rejected, (state, action) => {
        state.error = action.error;
      });
  },
});

export default dashboardStatsSlice.reducer;
