import React, { useState, useEffect, useCallback } from "react";
import "./userList.css"
import UsersTable from "../../components/UsersTable/UsersTable";
import UserFilters from "../../components/Filters/Filters";
import { getActions } from "../../constants/UserListConstants";
import { useModal } from "../../context/ModalContext";
import { useAlert } from "../../context/AlertContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { usersSelector, errorSelector } from "../../redux/users/selector";
import { eventsSelector } from "../../redux/events/selectors";
import { getUsersFromEvent, getUsers } from "../../redux/users/actions";
import PageHeadingWithOptions from "../../components/PageHeadingWithOptions/PageHeadingWithOptions";

const UserList = () => {
  // state from redux
  const users = useSelector(usersSelector);
  const error = useSelector(errorSelector);
  const events = useSelector(eventsSelector)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { showRedModal, showBlueModal, showCustomModal } = useModal();
  const { showSuccessAlert, showErrorAlert } = useAlert();

  const [usersList, setUsersList] = useState([]);
  const [areAllUsersSelected, setAreAllUsersSelected] = useState(false);
  const [userFilters, setUserFilters] = useState({
    isVisible: false,
    nameFilter: "",
    eventFilter: location.state?.selectedEventId || "",
    statusFilter: "",
  });

  const isFilterActive =
    userFilters.nameFilter ||
    userFilters.statusFilter ||
    userFilters.eventFilter;

  const fetchUserList = useCallback(() => {
    if (userFilters.eventFilter) {
      dispatch(
        getUsersFromEvent({
          eventId: userFilters.eventFilter,
          queryObject: { userDetails: true },
        })
      );
    } else {
      dispatch(getUsers());
    }
  }, [dispatch, userFilters.eventFilter])

  const userActions = getActions(
    showSuccessAlert,
    showErrorAlert,
    showRedModal,
    showBlueModal,
    showCustomModal,
    usersList,
    navigate,
    dispatch,
    fetchUserList,
    userFilters.eventFilter,
  );

  useEffect(() => {
    if (users) {
      setUsersList(users.map((user) => ({ ...user, isSelected: false })));
    }
  }, [users]);

  useEffect(() => {
    fetchUserList();
  }, [fetchUserList]);

  useEffect(() => {
    if (error) {
      showErrorAlert(error);
    }
  }, [error, showErrorAlert]);

  useEffect(() => {
    if (usersList.length > 0) {
      setAreAllUsersSelected(usersList.every((user) => user.isSelected));
    }
  }, [usersList]);

  // function returning filtered users list according to input in nameFilter
  function filteredUsersList() {
    let filteredUsers = usersList;
    if (userFilters.nameFilter) {
      filteredUsers = filteredUsers.filter((user) =>
        user.name.toLowerCase().includes(userFilters.nameFilter.toLowerCase())
      );
    }
    if (userFilters.statusFilter) {
      filteredUsers = filteredUsers.filter(
        (user) => user.status === userFilters.statusFilter
      );
    }
    return filteredUsers;
  }

  // function for handling the change of single user selection checkbox
  function handleUserSelection(event, id) {
    setUsersList((prevUsersList) =>
      prevUsersList.map((user) =>
        user.id === id ? { ...user, isSelected: event.target.checked } : user
      )
    );
  }

  // function for handling the change of all users selection checkbox
  function handleAllUsersSelectionChange(event) {
    setAreAllUsersSelected(event.target.checked);
    setUsersList((prevUsersList) =>
      prevUsersList.map((user) => ({
        ...user,
        isSelected: event.target.checked,
      }))
    );
  }

  // // function that handles change in filters
  function handleFilterChange(event) {
    setUserFilters((prevUserFilters) => ({
      ...prevUserFilters,
      [event.target.name]: event.target.value,
    }));
  }

  // function for clearing applied filters
  function clearFilter() {
    setUserFilters({ isVisible: false, nameFilter: "", statusFilter: "", eventFilter: "" });
  }

  // function to toggle filter visibility
  function toggleFilterVisibility() {
    setUserFilters((prevUserFilters) => ({
      ...prevUserFilters,
      isVisible: !prevUserFilters.isVisible,
    }));
  }

  return (
    <main id="main" className="main">
      <PageHeadingWithOptions
        pageHeading="Users / User List"
        actions={userActions}
        actionsTooltipText="Actions on selected users"
        filtersTooltipText="Filters"
        toggleFilterVisibility={toggleFilterVisibility}
        isFilterActive={isFilterActive}
        isFilterVisible={userFilters.isVisible}
        filterComponent={
          <UserFilters
            filters={userFilters}
            handleFilterChange={handleFilterChange}
            clearFilter={clearFilter}
            nameFilterPlaceholder="Enter User Name"
            eventOptions={events.map((event) => ({
              id: event.id,
              displayValue: event.name,
            }))}
            statusOptions={[
              { status: "active", displayValue: "Active" },
              { status: "inactive", displayValue: "Inactive" },
            ]}
            showCloseButton={true}
          />
        }
      />
      <UsersTable
        usersList={filteredUsersList()}
        handleUserSelection={handleUserSelection}
        handleAllUsersSelectionChange={handleAllUsersSelectionChange}
        areAllUsersSelected={areAllUsersSelected}
      />
    </main>
  );
};

export default UserList;
