import "./actionsDropdown.css";
import React from "react";

const ActionsDropdown = (props) => {
  return (
    <ul id="actions-dropdown" className="dropdown-list thin-scrollbar">
      {props.actions.map((action) => (
        <li key={action.place}>
          <button
          className="dropdown-unit d-flex align-items-center"
            onClick={action.onClick}
            disabled={action.disabled}
          >
            <span>{action.displayValue}</span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default ActionsDropdown;
