import React, { useRef, useEffect } from "react";
import "./messagePreview.css";
import useClickOutside from "../../utils/useClickOutside";
import { useMessagePreview } from "../../context/MessagePreviewContext";
import useDisableBodyScroll from "../../utils/useDisableBodyScroll";
import { stringToHtml } from "../../utils/commonUtils";

const MessagePreview = () => {
  const { messagePreview, setMessagePreview } = useMessagePreview();

  const modalRef = useRef(null);
  useClickOutside(modalRef, () => setMessagePreview({ isVisible: false }));
  useDisableBodyScroll(messagePreview.isVisible);

  const contentRef = useRef(null);
  useEffect(() => {
    // converting the string to html document
    const htmlContent = stringToHtml(messagePreview.message?.content)
    contentRef.current?.appendChild(htmlContent);
  }, [messagePreview]);

  if (messagePreview.isVisible) {
    return (
      <div className="message-modal-overlay">
        <div className="modal-content" ref={modalRef}>
          <h4 className="modal-heading">Message Preview</h4>
          <div className="w-100 px-5 py-3 d-flex flex-column gap-3">
            <h5 className="message-preview-heading">
              {messagePreview.message?.name}
            </h5>
            <div
              ref={contentRef}
              className="message-preview-content p-2 border border-1 thin-scrollbar"
            ></div>
          </div>
          <div className="modal-buttons">
            {messagePreview.primaryButtonText && (
              <button
                className="button rounded primary-button"
                onClick={messagePreview.primaryButtonOnClick}
              >
                {messagePreview.primaryButtonText}
              </button>
            )}
            <button
              className="button secondary-button rounded"
              onClick={() => setMessagePreview({ isVisible: false })}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default MessagePreview;
