export const dashboardNavItems = [
  {
    index: 1,
    name: "Dashboard",
    icon: "grid",
    isExpandable: false,
    path: "/dashboard",
    end: true,
    id: "dashboard-tab",
  },
  {
    index: 2,
    name: "Users",
    icon: "people",
    isExpandable: true,
    id: "users-tab",
    subItems: [
      {
        subIndex: 1,
        name: "User List",
        path: "user-list",
      },
      {
        subIndex: 2,
        name: "Add User",
        path: "add-user",
      },
    ],
  },
  {
    index: 3,
    name: "Events",
    icon: "calendar-check",
    isExpandable: true,
    id: "events-tab",
    subItems: [
      {
        subIndex: 1,
        name: "Event List",
        path: "list-event",
      },
      {
        subIndex: 2,
        name: "Add Event",
        path: "add-event",
      },
    ],
  },
  {
    index: 4,
    name: "Message Templates",
    icon: "envelope-paper",
    isExpandable: true,
    id: "messages-tab",
    subItems: [
      {
        subIndex: 1,
        name: "Message List",
        path: "message-list",
      },
      {
        subIndex: 2,
        name: "Add Template",
        path: "new-message",
      },
    ],
  },
  {
    index: 5,
    name: "Chat",
    icon: "chat-right-text",
    isExpandable: false,
    path: "chat",
    id: "chat-tab",
  },
];

export const publicNavItems = [
  {
    index: 1,
    name: "Home",
    path: "/",
  },
  // {
  //   index: 2,
  //   name: "License",
  //   path: "license",
  // },
  // {
  //   index: 3,
  //   name: "Try Now",
  //   path: ""
  // },
  {
    index: 4,
    name: "Sign In",
    path: "login",
  },
];