import { createAsyncThunk } from "@reduxjs/toolkit";
import { toUseDemoData, requestToServer } from "../../utils/commonUtils";
import { jwtDecode } from "jwt-decode";

// login action
export const loginAction = createAsyncThunk(
  "login/loginAction",
  async (loginInfo, thunkApi) => {
    if (toUseDemoData()) {
      return {
        data: {
          token: "123",
        },
      };
    } else {
      try {
        const loginData = await requestToServer("/login", {
          method: "POST",
          body: JSON.stringify(loginInfo),
        });
        const userInfo = jwtDecode(loginData?.token);
        // saving user's info in session storage
        sessionStorage.setItem('user', JSON.stringify({...loginData, ...userInfo}));
        return {
          data: {...loginData, ...userInfo}
        };
      } catch (e) {
        return thunkApi.rejectWithValue(e.message);
      }
    }
  }
);

export const refreshToken = createAsyncThunk("login/refreshToken", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const refreshToken = state.login?.user?.refreshToken;

  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/refresh`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${refreshToken}`
    }
  })
  const jsonResponse = await response.json();
  return jsonResponse.data;
})