import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
// import License from "./pages/License/License";
import PublicLayout from "./pages/PublicLayout/PublicLayout";
import PrivateRoute from "./PrivateRoute";
import DashboardLayout from "./pages/DashboardLayout/DashboardLayout";
import Dashboard from "./pages/Dashboard/Dashboard";
import UserList from "./pages/UserList/UserList";
import AddUser from "./pages/AddUser/AddUser";
import EditUser from "./pages/EditUser/EditUser";
import ListEvent from "./pages/ListEvent/ListEvent";
import AddEvent from "./pages/AddEvent/AddEvent";
import EditEvent from "./pages/EditEvent/EditEvent";
import EventAnnouncement from "./pages/EventAnnouncement/EventAnnouncement";
import MessageList from "./pages/MessageList/MessageList";
import NewMessage from "./pages/NewMessage/NewMessage";
import EditMessage from "./pages/EditMessage/EditMessage";
import Chat from "./pages/Chat/Chat";
import Login from "./pages/Login/Login";
import Configure from "./pages/Configure/Configure";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicLayout />}>
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/configure" element={<Configure />} />
          {/* <Route path="license" element={<License />} /> */}
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="user-list" element={<UserList />} />
            <Route path="user-list/edit-user" element={<EditUser />} />
            <Route path="add-user" element={<AddUser />} />
            <Route path="list-event" element={<ListEvent />} />
          <Route path="add-event" element={<AddEvent />} />
            <Route path="edit-event" element={<EditEvent />} />
            <Route path="event-announcement" element={<EventAnnouncement />} />
            <Route path="new-message" element={<NewMessage />} />
            <Route path="edit-message" element={<EditMessage />} />
            <Route path="message-list" element={<MessageList />} />
            <Route path="chat" element={<Chat />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
