import React, { useState, useRef } from "react";
import "./multiSelectDropdownWithSearch.css";
import useClickOutside from "../../utils/useClickOutside";
import SelectedItemTile from "../SelectedItemTile/SelectedItemTile";

/**
 * @param {Array} itemsList - state for array of objects with name, id and isSelected property.
 * @param {Function} setItemList - setter function for itemsList state.
 * @param {string} placeHolder - Text to be displayed as placeholder.
 * @param {string} searchPlaceHolder - Text to be displayed as placeholder in search bar of dropdown.
 * @param {string} listHeading - Text to be shown as heading where name property of options object will be shown.
 * @returns
 */
const MultiSelectDropdownWithSearch = ({
  itemList,
  setItemList,
  placeholder,
  searchPlaceHolder,
  listHeading,
}) => {
  // functionality to close the dropdown when clicked outside of it.
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setShowDropdown(false));

  // state to toggle visibility of dropdown
  const [showDropdown, setShowDropdown] = useState(false);
  // state for search filter
  const [nameFilter, setNameFilter] = useState("");
  // array containing filtered list according to input in search
  const filteredList =
    itemList?.filter((item) =>
      item.name?.toLowerCase().includes(nameFilter.toLowerCase())
    ) ?? [];

  // function for handling the change of name Filter
  function handleFilterChange(e) {
    setNameFilter(e.target.value);
  }

  // function to handle selection of item in list
  function handleItemSelection(event, item) {
    setItemList((prevList) =>
      prevList.map((listItem) =>
        listItem.id === item.id
          ? { ...listItem, isSelected: event.target.checked }
          : listItem
      )
    );
  }

  // function to deselect an item from list
  function handleItemUnSelection(id) {
    setItemList((prevList) =>
      prevList.map((listItem) =>
        listItem.id === id ? { ...listItem, isSelected: false } : listItem
      )
    );
  }
  return (
    <>
      <div ref={dropdownRef} className="w-100 position-relative">
        <div
          className="form-select"
          onClick={() =>
            setShowDropdown((prevShowDropdown) => !prevShowDropdown)
          }
        >
          <div className="border-0 cursor-pointer flex-grow-1 overflow-hidden">
            {placeholder}
          </div>
        </div>
        {showDropdown && (
          <div className="dropdown thin-scrollbar rounded border border-1">
            <div className="dropdown-filter d-flex flex-row align-items-center">
              <i className="bi bi-filter" style={{ fontSize: "1.4rem" }}></i>
              <input
                type="text"
                className="form-control dropdown-filter-input border-0 ms-1"
                placeholder={searchPlaceHolder}
                value={nameFilter}
                onChange={handleFilterChange}
                autoFocus
              />
            </div>
            <table className="table dropdown-table">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>{listHeading}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredList.map((item, index) => (
                  <ListItem
                    key={item.id}
                    serialNumber={index + 1}
                    item={item}
                    handleItemSelection={handleItemSelection}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="py-2">
          {itemList.filter(item => item.isSelected).map((item) => (
            <SelectedItemTile
              key={item.id}
              item={item}
              handleItemUnSelection={handleItemUnSelection}
            />
          ))}
        </div>
      </div>
    </>
  );
};
const ListItem = ({ serialNumber, item, handleItemSelection }) => {
  return (
    <tr>
      <td>{serialNumber}</td>
      <td>{item.name}</td>
      <td>
        <input
          className="form-check-input cursor-pointer"
          type="checkbox"
          checked={item.isSelected}
          onChange={(e) => handleItemSelection(e, item)}
        />
      </td>
    </tr>
  );
};

export default MultiSelectDropdownWithSearch;
