import "./sideList.css";
import { truncateString } from "../../utils/commonUtils";

const SideList = ({
  list,
  sideListHeading,
  selectedListItem,
  setSelectedListItem,
}) => {
  return (
    <div className="side-list bg-white border-end border-1 thin-scrollbar">
      <h5 className="chat-nav-heading">{sideListHeading}</h5>
      <ul>
        {list && list.length
          ? list.map((listItem) => (
              <li
                key={listItem.id}
                id={listItem.id}
                title={listItem.name}
                className={`chat-nav-item ${
                  listItem.id === selectedListItem?.id ? "active" : ""
                }`}
                onClick={() => setSelectedListItem(listItem)}
              >
                <i className="bi bi-circle"></i>
                <span>{truncateString(listItem.name, 15)}</span>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

export default SideList;
