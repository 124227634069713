import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./configure.css";
import Alert from "../../components/Alert/Alert";
import { useAlert } from "../../context/AlertContext";
import { useNavigate } from "react-router-dom";
import { requestToServer } from "../../utils/commonUtils";

const Configure = () => {
  const navigate = useNavigate();
  const { showErrorAlert, showSuccessAlert } = useAlert();

  const [configureForm, setConfigureForm] = useState({
    name: "",
    mobile: "",
    gender: "unknown",
    email: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setConfigureForm((prevConfigureForm) => ({
      ...prevConfigureForm,
      [name]: value,
    }));
  }

  async function handleSubmit() {
    try {
      await requestToServer("/configure", {
        method: "POST",
        body: JSON.stringify(configureForm),
      });
      showSuccessAlert("User created Successfully!");
      navigate("/login");
    } catch (error) {
      console.error(error);
      showErrorAlert(error.message);
    }
  }

  return (
    <>
      <main className="configure-main">
        <ConfigureForm
          configureForm={configureForm}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </main>
      <Alert className="mx-0 w-100 mb-5" />
    </>
  );
};

function ConfigureForm({ configureForm, handleChange, handleSubmit }) {
  return (
    <div className="row w-100 justify-content-center align-items-center">
      <div className="card col-xl-4 col-sm-12 col-md-8 p-4 p-xl-5 d-flex flex-column align-items-center">
        <h2 className="card-title">Register</h2>
        <div className="py-3 d-flex flex-column w-100">
          <label className="form-label">
            Name
            <input
              type="text"
              className="form-control"
              name="name"
              value={configureForm.name}
              onChange={handleChange}
            />
          </label>
          <label className="form-label">
            Mobile
            <input
              type="number"
              className="form-control"
              name="mobile"
              value={configureForm.mobile}
              onChange={handleChange}
            />
          </label>
          <label className="form-label">
            Gender
            <select
              name="gender"
              className="form-select"
              value={configureForm.gender}
              onChange={handleChange}
            >
              <option value="unknown">Not Selected</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </label>
          <label className="form-label">
            Email
            <input
              type="email"
              className="form-control"
              name="email"
              value={configureForm.email}
              onChange={handleChange}
            />
          </label>
        </div>
        <button
          className="button configure-button"
          disabled={!configureForm.mobile || !configureForm.name}
          onClick={handleSubmit}
        >
          Submit
        </button>
        <Link
          to="/login"
          className="info-text mt-3 p-0 text-decoration-underline"
        >
          Login?
        </Link>
      </div>
    </div>
  );
}

export default Configure;
