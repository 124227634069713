import Icon from "../Icon/Icon";

/**
 * Component which shows tiles of multiselect input
 * @param {Object} item - item object should have name(the text you want to display) and id property(unique)
 */
export default function SelectedItemTile({ item, handleItemUnSelection }) {
  return (
    <div className="d-flex align-items-center justify-content-between border-bottom border-2 px-2">
      <span>{item.name}</span>
      <Icon
        color="red"
        fontSize="1.4rem"
        onClick={() => handleItemUnSelection(item.id)}
      >
        <i className="bi bi-x"></i>
      </Icon>
    </div>
  );
}
