import { refreshToken } from "../redux/login/actions";

export const allowedMimeTypes = "text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel";
export const allowedFileTypes = "csv, xls, xlsx";

export function capitalizeString(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Check if the value is nullish(null or undefined)
 * @param {any} value 
 * @returns {boolean} whether the value is nullish
 */
export function isNullish(value) {
  if (value === null || value === undefined) {
    return true;
  }
  return false;
}

/**
 * Truncates a given string to a specified maximum length, appending an ellipsis (...) if the string exceeds the length.
 * @param {string} str - The string to be truncated.
 * @param {number} length - The maximum length of the truncated string.
 * @returns {string} The truncated string, with an ellipsis appended if the length exceeds the specified limit. Otherwise, the original string.

 */
export function truncateString(str, length) {
  if (str.length <= length) return str;
  return str.slice(0, length + 1) + "...";
}

/**
 * Converts the passed input string into html
 * @param {string} input 
 * @returns {html} The content of body tag wrapped in a div element when input string is converted into html document
 */
export function stringToHtml(input) {
  const parser = new DOMParser();
  const parsedDocument = parser.parseFromString(
    input,
    "text/html"
  );
  const container = document.createElement("div");
  container.innerHTML = parsedDocument.body.innerHTML;
  return container;
}


/**
 * Returns whether the demo data should be used.
 * @returns {boolean} Whether the demo data should be used.
 */
export function toUseDemoData() {
  return process.env.REACT_APP_USE_TEST_DATA;
}

/**
 * Parses the json response from server for error
 * @param {any} jsonResponse - Response of request from server converted to json
 * @returns {Error} when we receive an error property from server otherwise throws an error
 */
function parseForError(jsonResponse) {
  if (!jsonResponse.status) {
    throw new Error(`Unsupported response format: ${JSON.stringify(jsonResponse)}`);
  }
  const error = new Error();
  error.status = jsonResponse.status;
  error.message = jsonResponse.error;
  return error;
}

/**
 * Function that takes response object as parameter, reads expiry time from redux store and returns true if status of response is 401 and expiry of token has passed
 * @param {responseObject} response 
 * @returns {boolean}
 */
export function isTokenExpired(response) {
  const store = require("../redux/store").default;
  const expiry = store && store.getState()?.login?.user?.exp;
  return response.status === 401 && (Date.now >= expiry * 1000);
}

/**
 * Makes an HTTP request to the server.
 * @param {string} endpoint - The API endpoint to request.
 * @param {Object} [requestObject] - Additional options for the request, such as headers or body.
 * @returns {Object} The response data from the server.
 */
export async function requestToServer(endpoint, requestObject) {
  // getting token from redux store
  const store = require("../redux/store").default;
  const token = store && store.getState()?.login?.user?.token;

  const response = await fetch(process.env.REACT_APP_API_BASE_URL + endpoint, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    ...requestObject,
  });
  if (response.ok) {
    const jsonResponse = await response.json();
    if (jsonResponse.status === 0 && !jsonResponse.error) {
      return jsonResponse.data;
    } else {
      const error = parseForError(jsonResponse);
      throw error;
    }
  } else {
    if (isTokenExpired(response)) {
      await store.dispatch(refreshToken());
      return await requestToServer(endpoint, requestObject)
    }
    const error = new Error();
    error.status = response.status;
    error.message = response.statusText;
    throw error;
  }
}
