import React from "react";
import "./eventTable.css"
import Icon from "../Icon/Icon";
import AttachedMessages from "../AttachedMessages/AttachedMessages";
import { dateInUIFormat } from "../../utils/dateUtils";
import { capitalizeString } from "../../utils/commonUtils";
import { useModal } from "../../context/ModalContext";
import { Link } from "react-router-dom";

const EventTable = (props) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">S.No.</th>
          <th scope="col">Name</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col">Status</th>
          <th scope="col" className="text-center">
            Attached User
          </th>
          <th scope="col"></th>
          <th scope="col" className="text-center">
            Announcement
          </th>
          <th className="text-center">
            <input
              type="checkbox"
              className="form-check-input cursor-pointer"
              onChange={props.handleAllEventsSelectionChange}
              checked={props.areAllEventsSelected}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {props.eventsList &&
          props.eventsList.map((event, i) => (
            <EventEntry
              key={event.id}
              index={i}
              {...event}
              handleEventSelection={props.handleEventSelection}
            />
          ))}
      </tbody>
    </table>
  );
};

const EventEntry = (props) => {
  const { showCustomModal } = useModal();
  return (
    <tr>
      <td>{props.index + 1}</td>
      <td>{props.name}</td>
      <td>{dateInUIFormat(props.startDate)}</td>
      <td>{dateInUIFormat(props.endDate)}</td>
      <td>{capitalizeString(props.status)}</td>
      <td className="text-center">
        <Link to="/dashboard/user-list" state={{ selectedEventId: props.id }}>
          <Icon>
            <i className="bi bi-people-fill"></i>
          </Icon>
        </Link>
      </td>
      <td className="text-center">
        <Icon
          fontSize="1rem"
          onClick={() => {
            showCustomModal({
              title: "Attached Messages",
              customComponent: (
                <AttachedMessages eventId={props.id} />
              ),
            });
          }}
        >
          <i className="bi bi-three-dots-vertical"></i>
        </Icon>
      </td>
      <td className="text-center">
        <Link to="/dashboard/event-announcement" state={{ selectedEventId: props.id }}>
          <Icon fontSize="1rem">
            <i className="bi bi-megaphone-fill"></i>
          </Icon>
        </Link>
      </td>
      <td className="text-center">
        <input
          type="checkbox"
          className="form-check-input cursor-pointer"
          onChange={(e) => props.handleEventSelection(e, props.id)}
          checked={props.isSelected}
        />
      </td>
    </tr>
  );
};

export default EventTable;
