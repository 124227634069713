import React, { useEffect } from "react";
import "./dashboardLayout.css";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Navbar/Navbar";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import Alert from "../../components/Alert/Alert";
import MessagePreview from "../../components/MessagePreview/MessagePreview";
import { useTour } from "@reactour/tour";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../redux/users/actions";
import { getEvents } from "../../redux/events/actions";
import { getMessages } from "../../redux/messages/actions";
import { dashboardNavItems } from "../../constants/navbarItems";
import { useSetTourSteps } from "../../utils/useSetTourSteps";
import { getMyEvents } from "../../redux/myEvents/actions";

const DashboardLayout = () => {
  const { setCurrentStep, setIsOpen } = useTour();
  const dispatch = useDispatch();

  // custom hook to set only steps for which selectors are present in dom when location changes
  useSetTourSteps();
  // function for onclick of question mark on header
  function helpAction() {
    setIsOpen(true);
    setCurrentStep(0);
  }

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getEvents());
    dispatch(getMessages());
    dispatch(getMyEvents());
  }, [dispatch]);

  return (
    <div className="App">
      <Header publicHeader={false} helpAction={helpAction} />
      <Navbar navItems={dashboardNavItems} publicNavbar={false} />
      <Outlet />
      <ModalComponent />
      <MessagePreview />
      <Alert />
    </div>
  );
};

export default DashboardLayout;
