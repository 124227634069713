import "./card.css";
import React from "react";

const Card = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{props.heading}</h5>

        <div className="d-flex align-items-center">
          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
            {props.icon}
          </div>
          <h6 className="card-text">{props.value}</h6>
        </div>
      </div>
    </div>
  );
};

export default Card;
