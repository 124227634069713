import React from "react";
import "./newMessage.css"
import MessageEditor from "../../components/MessageEditor/MessageEditor";
import { createMessage } from "../../redux/messages/actions";
import PageHeadingWithOptions from "../../components/PageHeadingWithOptions/PageHeadingWithOptions";

const NewMessage = () => {
  return (
    <main id="main" className="new-message main">
      <PageHeadingWithOptions pageHeading="Message Templates / Add Template" />
      <MessageEditor submitAction={createMessage} />
    </main>
  );
};

export default NewMessage;
