import { createSlice } from "@reduxjs/toolkit";
import { getUsers, createUser, updateUser, updateMultipleUsers, deleteUsers, uploadUsersList, getUsersFromEvent, getAllUsers } from "./actions";

const initialState = {
  error: null,
  users: [],
  allUsers: [],
  loading: false,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.error = null;
      state.users = action.payload.data;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload;
    })
    builder.addCase(getUsersFromEvent.fulfilled, (state, action) => {
      state.error = null;
      state.users = action.payload.data;
    });
    builder.addCase(getUsersFromEvent.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(createUser.rejected, (_, action) => {
      throw new Error(action.payload);
    });
    builder.addCase(updateUser.rejected, (_, action) => {
      throw new Error(action.payload);
    });
    builder.addCase(updateMultipleUsers.rejected, (_, action) => {
      throw new Error(action.payload);
    })
    builder.addCase(deleteUsers.rejected, (_, action) => {
      throw new Error(action.payload);
    });
    builder.addCase(uploadUsersList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadUsersList.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(uploadUsersList.rejected, (state) => {
      state.loading = false;
    })
  },
});

export default usersSlice.reducer;
