import "./pageHeadingWithOptions.css";
import React, { useState, useRef } from "react";
import Icon from "../Icon/Icon";
import useClickOutside from "../../utils/useClickOutside";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import { Tooltip } from "react-tooltip";

const PageHeadingWithOptions = ({
  pageHeading,
  actions,
  actionsTooltipText,
  filtersTooltipText,
  toggleFilterVisibility,
  isFilterActive,
  isFilterVisible,
  filterComponent,
}) => {
  // state to toggle visibility of actions dropdown
  const [showActions, setShowActions] = useState(false);

  const dropdownRef = useRef(null);
  // closing action dropdown on outside click
  useClickOutside(dropdownRef, () => setShowActions(false));

  return (
    <div className="heading-container">
      <div className="pageTitle">
        {/* Page heading */}
        <h1>{pageHeading}</h1>
        <div className="d-flex gap-4 ms-auto">
          {/* Actions Icon */}
          {actions && (
            <div
              className="position-relative"
              ref={dropdownRef}
              onClick={() =>
                setShowActions((prevShowActions) => !prevShowActions)
              }
            >
              <Icon
                fontSize="1.4rem"
                className={showActions ? "active" : undefined}
              >
                <i
                  className="bi bi-pencil-square"
                  data-tooltip-id="actions"
                  data-tooltip-content={actionsTooltipText}
                  data-tooltip-place="left"
                ></i>
              </Icon>
              <Tooltip id="actions" />
              {showActions && <ActionsDropdown actions={actions} />}
            </div>
          )}
          {/* Filter Icon */}
          {filterComponent && (
            <>
              <Icon
                fontSize="1.4rem"
                className={isFilterActive ? "active" : undefined}
                onClick={toggleFilterVisibility}
              >
                <i
                  className="bi bi-funnel-fill"
                  data-tooltip-id="filter"
                  data-tooltip-content={filtersTooltipText}
                  data-tooltip-place="left"
                ></i>
              </Icon>
              <Tooltip id="filter" />
            </>
          )}
        </div>
      </div>
      <hr />
      {/* Filter component */}
      {isFilterVisible && filterComponent}
    </div>
  );
};

export default PageHeadingWithOptions;
