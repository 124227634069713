import { createSlice } from "@reduxjs/toolkit";
import { getMyEvents } from "./actions";

const myEventsSlice = createSlice({
  name: "myEvents",
  initialState: {
    error: null,
    myEvents: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMyEvents.fulfilled, (state, action) => {
      state.error = null;
      state.myEvents = action.payload;
    });
    builder.addCase(getMyEvents.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export default myEventsSlice.reducer;
