export const messages = [
  {
    id: 1,
    name: "Hello!",
    content: "Hello, How are you?",
    createdDate: "2023-10-23",
    status: "active",
    isSelected: false,
  },
  {
    id: 2,
    name: "POD-IT",
    content: "Hello, This is POD IT services",
    createdDate: "2024-10-22",
    status: "inactive",
    isSelected: false,
  },
];

export const eventChatMessages = {
  1: [
    { user: 1, message: "Hello! How are you?" },
    { user: 2, message: "Fine! How about you?" },
  ],
  2: [
    { user: 1, message: "Hello! This is POD IT services." },
    {
      user: 2,
      message: "Hello, How can i reach the center?",
    },
  ],
};

export const userChatMessages = {
  1: [
    { user: { id: 1, name: "Sumit" }, message: "Hello! How are you?" },
    { user: { id: 1, name: "Sumit" }, message: "Fine! How about you?" },
  ],
  2: [
    {
      user: { id: 2, name: "Amit" },
      message: "Hello! This is POD IT services.",
    },
    {
      user: { id: 2, name: "Amit" },
      message: "Hello, How can i reach the center?",
    },
  ],
};
