import { createAsyncThunk } from "@reduxjs/toolkit";
import { toUseDemoData } from "../../utils/commonUtils";
import { requestToServer } from "../../utils/commonUtils";
import eventsFormatterForUI from "../../formatters/eventsFormatter";
import { events } from "../../demo-data/events-demo-data";

// get my events
export const getMyEvents = createAsyncThunk("events/getMyEvents", async () => {
  if (toUseDemoData()) {
    return events;
  } else {
    const eventsAPIData = await requestToServer(`/getMyevents`);
    return eventsAPIData.map((data) => eventsFormatterForUI(data));
  }
});
