import React, { useState, useEffect } from "react";
import "./noInternetConnection.css";

const NoInternetConnection = ({ children }) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initialization set the isOnline state and eventListener.
  useEffect(() => {
    function setOnlineStatus() {
      setOnline(true);
    }

    function setOfflineStatus() {
      setOnline(false);
    }
    setOnline(navigator.onLine);

    // event listeners to update the state
    window.addEventListener("online", setOnlineStatus);
    window.addEventListener("offline", setOfflineStatus);

    return () => {
      // clean up
      window.removeEventListener("online", setOnlineStatus);
      window.removeEventListener("offline", setOfflineStatus);
    };
  }, []);

  // if user is online, return the child component else return a child component wth error
  if (isOnline) {
    return children;
  } else {
    return (
      <>
        {children}
        <NoInternetError />
      </>
    );
  }
};

export default NoInternetConnection;

function NoInternetError() {
  return (
    <div className="internet-error-container">
      <span>No Internet Connection.</span> <i class="bi bi-wifi-off"></i>
    </div>
  );
}
