import React from "react";
import "./usersTable.css"
import Icon from "../Icon/Icon";
import { capitalizeString } from "../../utils/commonUtils";
import { HashLink } from "react-router-hash-link";

const UsersTable = (props) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">S.No.</th>
          <th scope="col">Name</th>
          <th scope="col">Mobile Number</th>
          <th scope="col">Status</th>
          <th scope="col" className="text-center">
            Chat
          </th>
          <th className="text-center">
            <input
              type="checkbox"
              className="form-check-input cursor-pointer"
              onChange={props.handleAllUsersSelectionChange}
              checked={props.areAllUsersSelected}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {props.usersList &&
          props.usersList.map((user, i) => (
            <UserEntry
              key={user.id}
              index={i}
              {...user}
              handleUserSelection={props.handleUserSelection}
            />
          ))}
      </tbody>
    </table>
  );
};

// component for individual entries inside UsersTable
const UserEntry = (props) => {
  return (
    <tr>
      <td>{props.index + 1}</td>
      <td>{props.name}</td>
      <td>{props.mobile}</td>
      <td>{capitalizeString(props.status)}</td>
      <td className="text-center">
        <HashLink to={`/dashboard/chat#${props.id}`} state={{selectedUserId: props.id}}>
          <Icon fontSize="1rem">
            <i className="bi bi-chat-dots-fill"></i>
          </Icon>
        </HashLink>
      </td>
      <td className="text-center">
        <input
          type="checkbox"
          className="form-check-input cursor-pointer"
          onChange={(event) => props.handleUserSelection(event, props.id)}
          checked={props.isSelected}
        />
      </td>
    </tr>
  );
};
export default UsersTable;
