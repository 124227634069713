import React, { createContext, useContext, useState } from "react";

const MessagePreviewContext = createContext();

export const useMessagePreview = () => useContext(MessagePreviewContext);

export const MessagePreviewProvider = ({ children }) => {
  const [messagePreview, setMessagePreview] = useState({ isVisible: false });

  function showMessagePreview({ primaryButtonText, primaryButtonOnClick, message }) {
    setMessagePreview({
      isVisible: true,
      primaryButtonText,
      primaryButtonOnClick,
      message
    });
  }

  return (
    <MessagePreviewContext.Provider
      value={{ messagePreview, setMessagePreview, showMessagePreview }}
    >
      {children}
    </MessagePreviewContext.Provider>
  );
};
