import "./filters.css";
import React from "react";
import Icon from "../Icon/Icon";

const Filters = ({
  filters,
  handleFilterChange,
  clearFilter,
  nameFilterPlaceholder,
  eventOptions,
  statusOptions,
  showCloseButton,
}) => {
  return (
    <div id="filter-container" className="filter d-flex justify-content-between align-items-center gap-2 p-1">
      <div className="filter-div d-flex flex-row align-items-center">
        <i className="bi bi-filter" style={{ fontSize: "1.4rem" }}></i>
        <input
          type="text"
          className="filter-input border-0 ms-1"
          placeholder={nameFilterPlaceholder}
          name="nameFilter"
          value={filters.nameFilter}
          onChange={handleFilterChange}
          autoFocus
        />
      </div>
      <div className="d-flex align-items-center gap-2">
        {eventOptions && (
          <select
            className="form-select"
            name="eventFilter"
            value={filters.eventFilter}
            onChange={handleFilterChange}
          >
            <option value="" disabled hidden>
              Event
            </option>
            {eventOptions.map((eventOption, i) => (
              <option key={i} value={eventOption.id}>
                {eventOption.displayValue}
              </option>
            ))}
          </select>
        )}
        {statusOptions && (
          <select
            className="form-select"
            name="statusFilter"
            value={filters.statusFilter}
            onChange={handleFilterChange}
          >
            <option value="" disabled hidden>
              Status
            </option>
            {statusOptions.map((statusOption, i) => (
              <option key={i} value={statusOption.status}>
                {statusOption.displayValue}
              </option>
            ))}
          </select>
        )}
        {showCloseButton && (
          <Icon color="red" fontSize="1.4rem" onClick={clearFilter}>
            <i className="bi bi-x"></i>
          </Icon>
        )}
      </div>
    </div>
  );
};

export default Filters;
