import "./navbar.css";
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Navbar = ({ navItems, publicNavbar }) => {
  const location = useLocation();
  useEffect(() => {
    const isSmallScreen = window.innerWidth <= 1200;

    if (isSmallScreen) {
      document.body.classList.remove("toggle-sidebar");
    }
  }, [location]);
  return (
    <aside id="sidebar" className={`sidebar thin-scrollbar ${publicNavbar ? "public-navbar" : ""}`}>
      <ul className="sidebar-nav" id="sidebar-nav">
        {navItems.map((navItems) =>
          navItems.isExpandable ? (
            <ExpandableNavItem key={navItems.index} {...navItems} />
          ) : (
            <SimpleNavItem key={navItems.index} {...navItems} />
          )
        )}
      </ul>
    </aside>
  );
};

// component for displaying non-expandable nav items
const SimpleNavItem = function (props) {
  return (
    <li className="nav-item" id={props.id}>
      <NavLink
        to={props.path}
        className={({ isActive }) => `nav-link ${isActive ? "" : "collapsed"}`}
        end={props.end}
      >
        <i className={`bi bi-${props.icon}`}></i>
        <span>{props.name}</span>
      </NavLink>
    </li>
  );
};

// component for displaying expandable nav items
const ExpandableNavItem = function (props) {
  const location = useLocation()
  const pathName = location.pathname;
  const [showDropMenu, setShowDropMenu] = useState(false);
  const shouldHighlight = props.subItems.some(subItem => pathName.includes(subItem.path));

  // useEffect to keep the navItem open when some child inside it is active on page refresh
  useEffect(() => {
    if (shouldHighlight) {
      setShowDropMenu(true);
    } else {
      setShowDropMenu(false);
    }
  }, [props.subItems]);

  return (
    <li className="nav-item" id={props.id}>
      <div
        className={`nav-link ${shouldHighlight ? "" : "collapsed"} ${showDropMenu ? "active" : ""}`}
        onClick={() => setShowDropMenu((prevShowDropMenu) => !prevShowDropMenu)}
      >
        <i className={`bi bi-${props.icon}`}></i>
        <span>{props.name}</span>
        <i className="bi bi-chevron-down ms-auto"></i>
      </div>
      {showDropMenu && props.subItems && (
        <ul className="nav-content collapsed">
          {props.subItems.map((subItem) => (
            <ExpandedSubNavItem key={subItem.subIndex} {...subItem} />
          ))}
        </ul>
      )}
    </li>
  );
};

// component for displaying sub nav items of expandable nav items
const ExpandedSubNavItem = function (props) {
  return (
    <li>
      <NavLink
        to={props.path}
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        <i className="bi bi-circle"></i>
        <span>{props.name}</span>
      </NavLink>
    </li>
  );
};

export default Navbar;
