import { useState } from "react";
import { PinComponent } from "../../pages/Login/Login";
import { requestToServer } from "../../utils/commonUtils";
import { useAlert } from "../../context/AlertContext";
import { useModal } from "../../context/ModalContext";

const PinModal = ({ selectedUser }) => {
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const { setModal } = useModal();

  const [formInput, setFormInput] = useState({ pin: "", confirmPin: "" });
  const [error, setError] = useState(null);

  function handleChange(name, value) {
    setFormInput((prevFormInput) => ({ ...prevFormInput, [name]: value }));
  }

  async function handleSubmit() {
    try {
      if (formInput.pin === formInput.confirmPin) {
        await requestToServer("/password-reset", {
          method: "POST",
          body: JSON.stringify({
            userId: selectedUser.mobile,
            newPassword: formInput.pin,
          }),
        });
        setModal({ isVisible: false });
        showSuccessAlert("Pin reset successful!");
      } else {
        setError("Pin do not match!");
      }
    } catch (error) {
      setModal({ isVisible: false });
      showErrorAlert("Pin reset failed. Please try after some time.");
    }
  }
  return (
    <div className="pb-5">
      {error && <p className="text-danger text-center">{error}</p>}
      <PinComponent
        formInput={formInput}
        confirmPin
        buttonText="Reset"
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default PinModal;
