import React from "react";
import "./editMessage.css"
import MessageEditor from "../../components/MessageEditor/MessageEditor";
import { useLocation } from "react-router-dom";
import { updateMessage } from "../../redux/messages/actions";
import PageHeadingWithOptions from "../../components/PageHeadingWithOptions/PageHeadingWithOptions";

const EditMessage = () => {
  const location = useLocation();
  const props = location && location.state;
  return (
    <main id="main" className="edit-message main">
      <PageHeadingWithOptions pageHeading="Message Templates / Edit Template" />
      <MessageEditor message={props.message} submitAction={updateMessage} />
    </main>
  );
};

export default EditMessage;
