import React, { useRef, useEffect, useState } from "react";
import "./messageEditor.css";
import { editorOptions } from "../../constants/quillEditorOptions";
import Quill from "quill";
import { useAlert } from "../../context/AlertContext";
import { useDispatch } from "react-redux";
import { getMessages } from "../../redux/messages/actions";

const MessageEditor = ({ message, submitAction }) => {
  const dispatch = useDispatch();

  const { showSuccessAlert, showErrorAlert } = useAlert();
  const containerRef = useRef(null);
  const quillRef = useRef(null);

  const [messageName, setMessageName] = useState(message?.name || "");
  const [invalidFields, setInvalidFields] = useState({
    messageName: false,
    messageContent: false,
  });

  // function that sets the invalid fields and return true if there are any invalid fields else false
  function validateMessage() {
    let newInvalidFields = {};
    newInvalidFields.messageName = messageName === "";
    newInvalidFields.messageContent =
      /^((<[^<>]+>)+\s*(<br>)?\s*(<\/[^<>]+>)+)+$/i.test(
        quillRef.current?.root.innerHTML
      );
    setInvalidFields(newInvalidFields);
    return Object.values(newInvalidFields).some((value) => value);
  }

  async function handleMessageSave() {
    try {
      const isInvalid = validateMessage();
      if (isInvalid) {
        showErrorAlert("Please fill all the required fields!");
      } else {
        const messageDetail = quillRef.current.root.innerHTML;
        await dispatch(
          submitAction({
            id: message?.id,
            subject: messageName,
            messageType: "text",
            detail: messageDetail,
          })
        ).unwrap();
        dispatch(getMessages());
        showSuccessAlert("Message saved successfully!");
      }
    } catch (error) {
      showErrorAlert(error.message);
    }
  }

  // initializing quill editor
  useEffect(() => {
    const container = containerRef.current;
    const editorContainer = container.appendChild(
      container.ownerDocument.createElement("div")
    );
    const quill = new Quill(editorContainer, editorOptions);

    quillRef.current = quill;

    // if already existing data then show it in the editor
    if (message?.content) {
      const delta = quill.clipboard.convert({ html: message.content });
      quill.setContents(delta);
    }

    // handling image adding functionality
    const toolbar = quill.getModule("toolbar");
    toolbar.addHandler("image", function () {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      input.onchange = () => {
        const file = input.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = function (e) {
            const range = quill.getSelection(true);
            quill.insertEmbed(range.index, "image", e.target.result);
          };
          reader.readAsDataURL(file);
        }
      };
    });

    // cleanup function
    return () => {
      quillRef.current = null;
      container.innerHTML = "";
    };
  }, [quillRef, message]);

  return (
    <div className="card flex-grow-1 d-flex flex-column overflow-auto">
      <label className="form-label p-3">
        Message Name
        <input
          type="text"
          className={`form-control ${
            invalidFields.messageName ? "invalid-message-field" : ""
          }`}
          name="message name"
          value={messageName}
          onChange={(e) => setMessageName(e.target.value)}
        />
      </label>
      <div className="flex-grow-1 d-flex p-3">
        <div
          className={`d-flex flex-column flex-grow-1 overflow-auto ${
            invalidFields.messageContent ? "invalid-message-field" : ""
          }`}
          ref={containerRef}
        ></div>
      </div>
      <div className="d-flex justify-content-center p-3">
        <button
          className="button primary-button rounded"
          onClick={handleMessageSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default MessageEditor;
