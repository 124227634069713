import "./publicLayout.css";
import React, { useEffect } from "react";
import linkedinLogo from "../../assets/images/linkedin-logo.png";
import facebookLogo from "../../assets/images/facebook-logo.png";
import { useSelector } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";
import { isLoggedInSelector } from "../../redux/login/selectors";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Navbar/Navbar";
import { publicNavItems } from "../../constants/navbarItems"

const PublicLayout = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(isLoggedInSelector);
  useEffect(() => {
    if (isLoggedIn) navigate("/dashboard");
  }, [isLoggedIn, navigate]);
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header publicHeader />
      <Navbar publicNavbar navItems={publicNavItems} />
      <Outlet />
      <Footer />
    </div>
  );
};

function Footer() {
  return (
    <footer className="footer d-flex align-items-center flex-column-reverse flex-md-row gap-3">
      <span className="flex-grow-1">
        Copyright &copy; 2024,{" "}
        <a href={process.env.REACT_APP_PODIT_URL} className="footer-url">
          POD IT Services
        </a>
        , All Rights Reserved.
      </span>
      <span className="d-flex flex-row gap-3 fs-4">
        <a
          href={process.env.REACT_APP_PODIT_LINKEDIN_URL}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={linkedinLogo}
            alt="linkedin logo"
            height="30"
            style={{ backgroundColor: "#fff" }}
          ></img>
        </a>
        <a
          href={process.env.REACT_APP_PODIT_FACEBOOK_URL}
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebookLogo} alt="facebook logo" height="30"></img>
        </a>
      </span>
    </footer>
  );
}


export default PublicLayout;
