import "./header.css";
import React from "react";
import logo from "../../assets/images/public-logo.png";
import Icon from "../Icon/Icon";
import { Tooltip } from "react-tooltip";
import { logout } from "../../redux/login/reducers";
import { useDispatch } from "react-redux";
import { NavLink, Link } from "react-router-dom";

const Header = ({ publicHeader, helpAction }) => {
  function toggleSidebar() {
    const body = document.body;
    body.classList.toggle("toggle-sidebar");
  }
  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <Link id="logo" to={`${publicHeader ? "/" : "/dashboard"}`}>
        <img src={logo} alt="Logo" height="60" />
      </Link>
      <button
        className={`btn ms-auto toggle-sidebar-btn ${
          publicHeader ? "" : "always-visible"
        }`}
      >
        <i className="bi bi-list" onClick={toggleSidebar}></i>
      </button>
      <UserOptions publicHeader={publicHeader} helpAction={helpAction} />
    </header>
  );
};

/* This component renders either the navigation bar or logout option along with question mark icon for user depending upon whether the user is on Public Layout or Private Layout */
const UserOptions = ({ publicHeader, helpAction }) => {
  const dispatch = useDispatch();

  return (
    <>
      {publicHeader ? (
        <menu className="d-flex gap-5 align-items-center justify-content-between px-5 ms-auto public-nav">
          <NavLink
            to="/"
            className={({ isActive }) =>
              `${isActive ? "active" : ""} fs-6 fw-bold`
            }
            end
          >
            Home
          </NavLink>
          {/* <NavLink
                to="/license"
                className={({ isActive }) =>
                `${isActive ? "active" : ""} fs-6 fw-bold`
                }
              >
                License
              </NavLink> */}
          {/* <button id="try-now-btn" className="btn px-3 py-1 fs-6 fw-bold rounded">
                Try Now
              </button> */}
          <NavLink to="/login" className="fs-6 fw-bold">
            Sign In
          </NavLink>
        </menu>
      ) : (
        <div className="d-flex flex-row gap-3 me-3 ms-auto">
          <Icon
            color="var(--header-clr)"
            fontSize="1.5rem"
            onClick={helpAction}
          >
            <i
              className="bi bi-question-circle"
              data-tooltip-id="tour"
              data-tooltip-content="Tour"
              data-tooltip-place="left"
            ></i>
          </Icon>
          <Tooltip id="tour" />

          <Icon
            color="var(--header-clr)"
            fontSize="1.5rem"
            onClick={() => dispatch(logout())}
          >
            <i
              className="bi bi-box-arrow-right"
              data-tooltip-id="logout"
              data-tooltip-content="Logout"
            ></i>
          </Icon>
          <Tooltip id="logout" />
        </div>
      )}
    </>
  );
};

export default Header;
