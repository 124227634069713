import React, { useState, useEffect } from "react";
import "./eventAnnouncement.css";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAlert } from "../../context/AlertContext";
import EventSideList from "../../components/SideList/SideList";
import MessageInput from "../../components/MessageInput/MessageInput";
import ChatMessage from "../../components/ChatMessage/ChatMessage";
import PageHeadingWithOptions from "../../components/PageHeadingWithOptions/PageHeadingWithOptions";
import { useXmppService } from "../../context/xmppContext";
import {
  myEventsSelector,
  errorSelector,
} from "../../redux/myEvents/selectors";

const EventAnnouncement = () => {
  const myEvents = useSelector(myEventsSelector);
  const error = useSelector(errorSelector);
  const { showErrorAlert } = useAlert();
  const { messages, sendMessage } = useXmppService(); // instance of xmppService created after successful login

  const location = useLocation();
  const selectedEventId = location.state?.selectedEventId || null; // getting selected event id from page state

  const [selectedEvent, setSelectedEvent] = useState(selectedEventId);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [messageInput, setMessageInput] = useState("");

  const messagesForCurrentSelection = messages[selectedEvent?.jid] ?? [];

  function sendFunctionality() {
    sendMessage(selectedEvent.jid, messageInput, "groupchat");
    setMessageInput("");
  }

  useEffect(() => {
    if (error) {
      showErrorAlert(error);
    }
  }, [error, showErrorAlert]);

  return (
    <main id="main" className="event-announcement main">
      <PageHeadingWithOptions pageHeading="Events / Event Announcement" />
      <div className="event-announcement-container d-flex flex-row flex-grow-1 h-100 border border-1">
        <EventSideList
          list={myEvents}
          sideListHeading="Event Name"
          selectedListItem={selectedEvent}
          setSelectedListItem={setSelectedEvent}
        />
        {selectedEvent && (
          <div className="flex-grow-1 d-flex flex-column overflow-auto">
            <div className="flex-grow-1 d-flex flex-column align-items-start overflow-auto thin-scrollbar p-3 gap-2">
              {messagesForCurrentSelection && messagesForCurrentSelection.length
                ? messagesForCurrentSelection.map((chatMessage, i) => (
                    <ChatMessage
                      key={i}
                      message={chatMessage.content}
                      user={chatMessage.user}
                      selfMessage={chatMessage.direction === "outgoing"}
                    />
                  ))
                : null}
            </div>
            <MessageInput
              messageInput={messageInput}
              selectedTab={selectedEvent}
              setMessageInput={setMessageInput}
              setUploadedFile={setUploadedFile}
              sendMessage={sendFunctionality}
            />
          </div>
        )}
      </div>
    </main>
  );
};

export default EventAnnouncement;
