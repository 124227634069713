import React, { useState, useRef, useEffect } from "react";
import "./addUser.css";
import AddUserForm from "../../components/AddUserForm/AddUserForm"
import { useAlert } from "../../context/AlertContext";
import { useDispatch, useSelector } from "react-redux";
import { createUser, getAllUsers, uploadUsersList } from "../../redux/users/actions";
import { useLocation } from "react-router-dom";
import { useModal } from "../../context/ModalContext";
import PageHeadingWithOptions from "../../components/PageHeadingWithOptions/PageHeadingWithOptions";
import { allUsersSelector } from "../../redux/users/selector";

const AddUser = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { showSuccessAlert } = useAlert();
  const { setModal, showRedModal } = useModal();
  const fileInputElement = useRef(null);

  // state for all fields of add user form
  const [userDetails, setUserDetails] = useState({
    name: "",
    mobile: "",
    isAdmin: false,
    files: null,
  });  
  const [selectedEventId, setSelectedEventId] = useState(location.state?.selectedEventId || null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showSingleUserForm, setShowSingleUserForm] = useState(true);

  const allUsers = useSelector(allUsersSelector);

  // state for selected users in Add Multiple tab
  const [userList, setUserList] = useState([])


  // function to handle change in input fields of add user form
  function handleInputChange(event) {
    const { name, value, checked } = event.target;
    setUserDetails({
      ...userDetails,
      [name]: event.target.type === "checkbox" ? checked : value,
    });
  }

  // getting list of user through api call
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    setUserList(allUsers.map(user => ({...user, isSelected: false})))
  }, [allUsers])

  // function to handle change in checkbox to select events in event dropdown
  function handleEventSelection(id) {
      setSelectedEventId(id);
  }

  // function to handle change in user selection
  function handleUserSelection(id) {
    setSelectedUserId(id);
    const selectedUser = allUsers.find((user) => user.id === id);
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      name: selectedUser.name,
      mobile: selectedUser.mobile,
    }));
  }

  // function for handling the selection of file
  function handleFileUpload(files) {
    setUserDetails({ ...userDetails, files });
  }

  // functionality to reset the form
  function handleResetForm() {
    setSelectedEventId(null);
    setUserDetails({
      name: "",
      mobile: "",
      isAdmin: false,
      files: null,
    });
    setSelectedUserId(null);
    if(fileInputElement.current){
      fileInputElement.current.files = null;
    }
    setUserList(prevUserList => prevUserList.map(user => ({...user, isSelected: false})))
  }

  async function handleSubmit() {
    try {
      if(showSingleUserForm){
        await dispatch(
          createUser({
            users: [
              {
                name: userDetails.name,
                mobile: userDetails.mobile,
                role: userDetails.isAdmin ? "admin" : "standard",
              },
            ],
            eventId: selectedEventId ? selectedEventId : undefined,
          })
        );
        dispatch(getAllUsers());
        setUserDetails((prevUserDetails) => ({
          ...prevUserDetails,
          name: "",
          mobile: "",
          isAdmin: false,
        }));
        showSuccessAlert("User added successfully!");
      }else{
        if (userDetails.files) {
          const formData = new FormData();
          formData.append("file", userDetails.files?.[0]);
          selectedEventId && formData.append("eventId", selectedEventId);
          await dispatch(uploadUsersList(formData)).unwrap();
          setUserDetails((prevUserDetails) => ({
            ...prevUserDetails,
            files: null,
          }));
          dispatch(getAllUsers());
          fileInputElement.current.files = null;
          showSuccessAlert("User List Uploaded!");
        } else {
          await dispatch(
            createUser({
              users: userList
                .filter((user) => user.isSelected)
                .map(({ name, mobile }) => ({ name, mobile })),
              eventId: selectedEventId ? selectedEventId : undefined,
            })
          );
          showSuccessAlert("Users added successfully!")
        }
      }
    } catch (error) {
      console.error(error);
      showRedModal({
        title: "Error",
        modalText: error.message,
        buttonText: "Ok",
        onClick: () => setModal({ isVisible: false }),
      });
    }
  }

  return (
    <main id="main" className="main">
      <PageHeadingWithOptions pageHeading="Users / Add User" />
      <div className="d-flex row justify-content-center">
        <AddUserForm
          userDetails={userDetails}
          fileInputElement={fileInputElement}
          showSingleUserForm={showSingleUserForm}
          setShowSingleUserForm={setShowSingleUserForm}
          selectedEventId={selectedEventId}
          selectedUserId={selectedUserId}
          userList={userList}
          setUserList={setUserList}
          handleInputChange={handleInputChange}
          handleEventSelection={handleEventSelection}
          handleFileUpload={handleFileUpload}
          handleSubmit={handleSubmit}
          handleResetForm={handleResetForm}
          handleUserSelection={handleUserSelection}
        />
      </div>
    </main>
  );
};

export default AddUser;
