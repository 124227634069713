import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-date-picker/dist/DatePicker.css";
import "quill/dist/quill.snow.css";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ModalProvider } from "./context/ModalContext";
import { AlertProvider } from "./context/AlertContext";
import { MessagePreviewProvider } from "./context/MessagePreviewContext";
import { Provider } from "react-redux";
import store from "./redux/store";
import { TourProvider } from "@reactour/tour";
import { tourStyles } from "./constants/tourConstants";
import NoInternetConnection from "./components/NoInternetConnection/NoInternetConnection";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <NoInternetConnection>
  <Provider store={store}>
    <ModalProvider>
      <MessagePreviewProvider>
        <AlertProvider>
          <TourProvider styles={tourStyles}>
           <App />
          </TourProvider>
        </AlertProvider>
      </MessagePreviewProvider>
    </ModalProvider>
  </Provider>
  </NoInternetConnection>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
