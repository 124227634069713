import {
  deleteMessage,
  getMessages,
  updateMessage,
} from "../redux/messages/actions";

export const getMessageActions = (
  showRedModal,
  showBlueModal,
  showSuccessAlert,
  showErrorAlert,
  messagesList,
  navigate,
  dispatch
) => {
  const selectedMessages = messagesList.filter((message) => message.isSelected);
  const selectedMessagesLength = selectedMessages.length;
  return [
    {
      place: 1,
      displayValue: "Delete",
      disabled: selectedMessagesLength === 0,
      onClick() {
        showRedModal({
          title: `Delete Message${selectedMessagesLength > 1 ? "s" : ""}?`,
          modalText: `Are you sure you want to delete ${
            selectedMessagesLength > 1 ? "these messages" : "this message"
          }?`,
          buttonText: "Delete",
          warningText: `If you delete ${
            selectedMessagesLength > 1 ? "these messages" : "this message"
          }, related information will be deleted.`,
          async onClick() {
            // delete message functionality
            try {
              const deleteRequests = selectedMessages.map((message) =>
                dispatch(deleteMessage({ id: message.id }))
              );
              await Promise.all(deleteRequests);
              dispatch(getMessages());
              const message = `${
                selectedMessagesLength > 1 ? "Messages" : "Message"
              } deleted successfully!`;
              showSuccessAlert(message);
            } catch (error) {
              console.error(error);
              showErrorAlert(error.message);
            }
          },
        });
      },
    },
    {
      place: 2,
      displayValue: "Edit",
      disabled: !(selectedMessagesLength === 1),
      onClick() {
        navigate("/dashboard/edit-message", { state: { message: selectedMessages[0] } });
      },
    },
    {
      place: 3,
      displayValue: "Deactivate",
      disabled: selectedMessagesLength === 0,
      onClick() {
        showRedModal({
          title: `Deactivate Message${selectedMessagesLength > 1 ? "s" : ""}?`,
          modalText: `Are you sure you want to deactivate ${
            selectedMessagesLength > 1 ? "these messages" : "this message"
          }?`,
          buttonText: "Deactivate",
          async onClick() {
            // deactivate message functionality
            try {
              const deactivateRequests = selectedMessages.map((message) =>
                dispatch(updateMessage({ id: message.id, status: 0 }))
              );
              await Promise.all(deactivateRequests);
              dispatch(getMessages());
              const message = `${
                selectedMessagesLength > 1 ? "Messages" : "Message"
              } deactivated successfully!`;
              showSuccessAlert(message);
            } catch (error) {
              console.error(error);
              showErrorAlert(error.message);
            }
          },
        });
      },
    },
    {
      place: 4,
      displayValue: "Activate",
      disabled: selectedMessagesLength === 0,
      onClick() {
        showBlueModal({
          title: `Activate Message${selectedMessagesLength > 1 ? "s" : ""}?`,
          modalText: `Are you sure you want to activate ${
            selectedMessagesLength > 1 ? "these messages" : "this message"
          }?`,
          buttonText: "Activate",
          async onClick() {
            // activate message functionality
            try {
              const activateRequests = selectedMessages.map((message) =>
                dispatch(updateMessage({ id: message.id, status: 1 }))
              );
              await Promise.all(activateRequests);
              dispatch(getMessages());
              const message = `${
                selectedMessagesLength > 1 ? "Messages" : "Message"
              } activated successfully!`;
              showSuccessAlert(message);
            } catch (error) {
              console.error(error);
              showErrorAlert(error.message);
            }
          },
        });
      },
    },
  ];
};
