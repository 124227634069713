export const events = [
  {
    id: "1",
    name: "Event 1",
    startDate: "2023-11-10",
    endDate: "2023-11-23",
    status: "active",
    userIds: ["1"],
    attachedMessages: [{ id: 1, name: "Hello!" }],
  },

  {
    id: "2",
    name: "Event 2",
    startDate: "2023-12-01",
    endDate: "2023-12-15",
    status: "inactive",
    userIds: ["2"],
    attachedMessages: [{ id: 2, name: "POD-IT" }],
  },
];

export const coloredDates = ["2024-03-31", "2024-04-02", "2024-04-05"];
