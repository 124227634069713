import "./attachedMessages.css";
import React, { useEffect, useState, useCallback } from "react";
import { useModal } from "../../context/ModalContext";
import { getEventById } from "../../redux/events/actions";
import { useDispatch } from "react-redux";
import { useAlert } from "../../context/AlertContext";
import { messageFormatter } from "../../formatters/messageFormatter";

const AttachedMessages = ({ eventId }) => {
  const dispatch = useDispatch();
  const { setModal } = useModal();
  const { showErrorAlert } = useAlert();

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState();

  const getAttachedMessages = useCallback(async () => {
    try {
      setLoading(true);
      const eventData = await dispatch(
        getEventById({ eventId, queryObject: { messageDetails: true } })
      ).unwrap();
      setMessages(
        eventData.messages.map((message) => messageFormatter(message))
      );
    } catch (error) {
      console.error(error);
      showErrorAlert(error.message);
    } finally {
      setLoading(false);
    }
  }, [dispatch, showErrorAlert, eventId]);

  useEffect(() => {
    getAttachedMessages();
  }, [getAttachedMessages]);

  if (loading) return;

  return (
    <div className="w-100 py-2 px-4">
      {messages && messages.length > 0 ? (
        <div className="message-modal-table">
          <ul className="d-flex flex-row p-2">
            <li className="first-list-item fw-bold">S.No.</li>
            <li className="second-list-item fw-bold">Message Name</li>
          </ul>
          <div className="message-modal-table-body thin-scrollbar">
            {messages.map((message, index) => (
              <ul
                key={index}
                className="d-flex flex-row justify-content-around border-top border-1 p-2"
              >
                <li className="first-list-item">{index + 1}</li>
                <li className="second-list-item">{message.name}</li>
              </ul>
            ))}
          </div>
        </div>
      ) : (
        "No messages attached to the event"
      )}
      <div className="modal-buttons">
        <button
          className="button rounded primary-button"
          onClick={() => setModal({ isVisible: false })}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AttachedMessages;
