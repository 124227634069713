import { configureStore } from "@reduxjs/toolkit";
import dashboardStatsReducer from "./dashboardStats/reducers"
import loginReducer from "./login/reducers";
import usersReducer from "./users/reducers";
import eventsReducer from "./events/reducers";
import myEventsReducer from "./myEvents/reducers";
import messageReducer from "./messages/reducers";

export default configureStore({
  reducer: {
    dashboardStats: dashboardStatsReducer,
    login: loginReducer,
    users: usersReducer,
    events: eventsReducer,
    myEvents: myEventsReducer,
    messages: messageReducer,
  },
});
