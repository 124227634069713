export const users = [
  {
    id: "1",
    name: "Sumit",
    mobile: "342342342",
    status: "inactive",
  },
  {
    id: "2",
    name: "Amit",
    mobile: "342342342",
    status: "active",
  },
];

export const filteredUsers = [
  {
    id: "3",
    name: "Prashant",
    mobile: "9301038291",
    status: "active",
  },
  {
    id: "4",
    name: "Someone",
    mobile: "9381011892",
    status: "inactive",
  },
];
