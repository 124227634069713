import "./attachMessageComponent.css";
import Filters from "../Filters/Filters";
import Icon from "../Icon/Icon";
import { useMessagePreview } from "../../context/MessagePreviewContext";

const AttachMessagesComponent = (props) => {
  const { showMessagePreview, setMessagePreview } = useMessagePreview();
  return (
    <div id="message-attach" className="message-attach card col-sm-12 col-md-8 col-xl-5 thin-scrollbar">
      {/* Filters Component */}
      <Filters
        filters={props.messageFilters}
        handleFilterChange={props.handleFilterChange}
        nameFilterPlaceholder="Enter Message Name"
      />
      {/* Table of Messages */}
      {props.messagesList && (
        <table className="table mb-3">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Message Name</th>
              <th className="text-center">Preview</th>
              <th className="text-center">
                <input
                  type="checkbox"
                  className="form-check-input cursor-pointer"
                  checked={props.areAllMessagesSelected}
                  onChange={props.handleAllMessagesSelectionChange}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {props.messagesList.map((message, index) => (
              <tr key={message.id}>
                <td>{index + 1}</td>
                <td>{message.name}</td>
                <td className="text-center">
                  <Icon
                    onClick={() =>
                      showMessagePreview({
                        primaryButtonText: "Attach",
                        primaryButtonOnClick: () => {
                          setMessagePreview({ isVisible: false });
                          props.handleMessageSelectionChange(true, message.id);
                        },
                        message: {
                          name: message.name,
                          content: message.content,
                        },
                      })
                    }
                  >
                    <i className="bi bi-eye-fill"></i>
                  </Icon>
                </td>
                <td className="text-center">
                  <input
                    type="checkbox"
                    className="form-check-input cursor-pointer"
                    checked={message.isSelected}
                    onChange={(e) =>
                      props.handleMessageSelectionChange(
                        e.target.checked,
                        message.id
                      )
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AttachMessagesComponent;
