import React, { useState } from "react";
import "./chat.css";
import UserSideList from "../../components/SideList/SideList";
import MessageInput from "../../components/MessageInput/MessageInput";
import ChatMessage from "../../components/ChatMessage/ChatMessage";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { allUsersSelector } from "../../redux/users/selector";
import { useXmppService } from "../../context/xmppContext";
import PageHeadingWithOptions from "../../components/PageHeadingWithOptions/PageHeadingWithOptions";

const Chat = () => {
  const { messages, sendMessage } = useXmppService(); // instance of xmppService created after successful login
  const location = useLocation();
  const selectedUserId = location.state?.selectedUserId || null; // getting selected user id from page state
  const users = useSelector(allUsersSelector);
  const [selectedUser, setSelectedUser] = useState(
    users.find((user) => user.id === selectedUserId)
  );
  const [uploadedFile, setUploadedFile] = useState(null);
  const [messageInput, setMessageInput] = useState("");

  const messagesForCurrentSelection = messages[selectedUser?.jid] ?? [];

  function sendFunctionality() {
    sendMessage(selectedUser.jid, messageInput, "chat");
    setMessageInput("");
  }

  return (
    <main id="main" className="chat main">
      <PageHeadingWithOptions pageHeading="Chat" />
      <div className="chat-container d-flex flex-row flex-grow-1 h-100 border border-1">
        <UserSideList
          list={users}
          sideListHeading="User Name"
          selectedListItem={selectedUser}
          setSelectedListItem={setSelectedUser}
        />
        {selectedUser && (
          <div className="flex-grow-1 d-flex flex-column overflow-auto">
            <div className="flex-grow-1 d-flex flex-column align-items-start overflow-auto thin-scrollbar p-3 gap-2">
              {messagesForCurrentSelection && messagesForCurrentSelection.length
                ? messagesForCurrentSelection.map((chatMessage, i) => (
                    <ChatMessage
                      key={i}
                      message={chatMessage.content}
                      user={chatMessage.user}
                      selfMessage={chatMessage.direction === "outgoing"}
                    />
                  ))
                : null}
            </div>
            <MessageInput
              messageInput={messageInput}
              selectedTab={selectedUser}
              setMessageInput={setMessageInput}
              setUploadedFile={setUploadedFile}
              sendMessage={sendFunctionality}
            />
          </div>
        )}
      </div>
    </main>
  );
};

export default Chat;
