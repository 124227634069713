import React, { useRef, useState } from "react";
import "./messageInput.css";
import Icon from "../Icon/Icon";
import MessageModal from "../MessageModal/MessageModal";
import useClickOutside from "../../utils/useClickOutside";
import { useModal } from "../../context/ModalContext";

const MessageInput = ({
  messageInput,
  selectedTab,
  setMessageInput,
  setUploadedFile,
  sendMessage,
}) => {
  const { showCustomModal } = useModal();
  const [showFileUploadOptions, setShowFileUploadOptions] = useState(false);
  const fileUploadWindowRef = useRef();
  useClickOutside(fileUploadWindowRef, () => setShowFileUploadOptions(false));
  return (
    <div className="d-flex flex-row gap-2 m-2">
      <form
        className="event-announcement-message border border-1 flex-grow-1 d-flex flex-row align-items-center px-2 position-relative"
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage();
        }}
      >
        <input
          type="text"
          className="flex-grow-1"
          placeholder="Message"
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
        />
        <div ref={fileUploadWindowRef}>
          <Icon
            fontSize="1.25rem"
            onClick={() =>
              setShowFileUploadOptions(
                (prevShowFileUploadOptions) => !prevShowFileUploadOptions
              )
            }
          >
            <i className="bi bi-paperclip"></i>
          </Icon>
          {showFileUploadOptions && (
            <div className="file-upload-options rounded border border-1">
              <div>
                <Icon
                  onClick={() => {
                    setShowFileUploadOptions(false);
                    showCustomModal({
                      title: "Message List",
                      customComponent: (
                        <MessageModal selectedTab={selectedTab} />
                      ),
                    });
                  }}
                  fontSize="2rem"
                  className="file-input-icon border border-1"
                >
                  <i className="bi bi-chat-right-text-fill"></i>
                </Icon>
                <span className="label-text">Message</span>
              </div>
              <FileInput
                icon={<i className="bi bi-file-earmark-text-fill"></i>}
                labelText="Document"
                type=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.ppt,.pptx,.txt"
                setUploadedFile={setUploadedFile}
              />
              <FileInput
                icon={<i className="bi bi-image"></i>}
                labelText="Image"
                type="image/*"
                setUploadedFile={setUploadedFile}
              />
              <FileInput
                icon={<i className="bi bi-headphones"></i>}
                labelText="Audio"
                type="audio/*"
                setUploadedFile={setUploadedFile}
              />
              <FileInput
                icon={<i className="bi bi-camera-video-fill"></i>}
                labelText="Video"
                type="video/*"
                setUploadedFile={setUploadedFile}
              />
            </div>
          )}
        </div>
      </form>
      <button type="submit" className="send-btn">
        <Icon>
          <i className="bi bi-send-fill"></i>
        </Icon>
      </button>
    </div>
  );
};

function FileInput({ icon, labelText, type, setUploadedFile }) {
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFile(file);
    }
  };
  return (
    <label className="d-flex flex-column align-items-center cursor-pointer">
      <input
        type="file"
        className="file-upload-input"
        accept={type}
        onChange={handleFileChange}
        hidden
      />
      <Icon fontSize="2rem" className="file-input-icon border border-1">
        {icon}
      </Icon>
      <span className="label-text">{labelText}</span>
    </label>
  );
}
export default MessageInput;
