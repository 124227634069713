import React, { useRef, useEffect } from "react";
import "./chatMessage.css";
import { stringToHtml } from "../../utils/commonUtils";

const ChatMessage = ({ message, selfMessage, user }) => {
  const messageRef = useRef(null);
  useEffect(() => {
    const htmlContent = stringToHtml(message);
    messageRef.current?.appendChild(htmlContent);
  }, [message])
  return (
    <div
      className={`message-container ${
        selfMessage ? "message-right tail-out" : "tail-in"
      }`}
    >
      {user && user.name && <strong>{user.name}: </strong>}
      <span ref={messageRef}></span>
    </div>
  );
};

export default ChatMessage;
