import { dateInApiFormat } from "../utils/dateUtils";
import statusFormatter, { statusFormatterForAPI } from "./statusFormatter";

export default function eventsFormatterForUI(eventObject) {
  const { title, ejaberd, ...eventInfo } = eventObject;
  return {
    name: title,
    jid: ejaberd,
    ...eventInfo,
    status: statusFormatter(eventInfo.status),
  };
}
export function eventsFormatterForAPI(eventObject) {
  const { name, ...eventInfo } = eventObject;
  return {
    title: name,
    ...eventInfo,
    startDate: dateInApiFormat(eventInfo.startDate),
    endDate: dateInApiFormat(eventInfo.endDate),
    status: statusFormatterForAPI(eventInfo.status),
  };
}
