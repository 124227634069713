import React from "react";
import { useSelector } from "react-redux";
import { isLoggedInSelector } from "./redux/login/selectors";
import { Navigate, Outlet } from "react-router-dom";
import { XmppServiceProvider } from "./context/xmppContext";

const PrivateRoute = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }
  return (
    <XmppServiceProvider>
      <Outlet />
    </XmppServiceProvider>
  );
};

export default PrivateRoute;
