import "./icon.css";
import React from "react";

const Icon = ({
  color,
  fontSize = "1rem",
  className,
  children,
  onClick,
}) => {
  const style = { color, fontSize };
  return (
    <div
      className={`icon ${className ? className : ""}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Icon;
