import React, { useState } from "react";
import "./login.css";
import Alert from "../../components/Alert/Alert";
import PinInput from "react-pin-input";
import { useAlert } from "../../context/AlertContext";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAction } from "../../redux/login/actions";
import { requestToServer } from "../../utils/commonUtils";

const Login = () => {
  return (
    <>
      <main className="login-main">
        <LoginForm />
      </main>
      <Alert className="mx-0 w-100 mb-5" />
    </>
  );
};

// Login Form Component
function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showErrorAlert } = useAlert();

  // state for login
  const [loginForm, setLoginForm] = useState({
    mobile: "",
    pin: "",
    confirmPin: "",
  });
  const [firstLogin, setFirstLogin] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  // function that handles change in input fields
  function handleChange(name, value) {
    setLoginForm((prevLoginForm) => ({ ...prevLoginForm, [name]: value }));
  }

  // function to validate the user's mobile and move to next step
  async function handleNext(e) {
    e.preventDefault();
    try {
      await requestToServer("/validate", {
        method: "POST",
        body: JSON.stringify({ mobile: loginForm.mobile }),
      });
      setCurrentStep((prevStep) => prevStep + 1);
    } catch (error) {
      if (error.status === 8) {
        setFirstLogin(true);
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        console.error(error);
        showErrorAlert(error.message);
      }
    }
  }

  // function to handle login attempt
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (firstLogin) {
        if (loginForm.pin === loginForm.confirmPin) {
          await requestToServer("/password-reset", {
            method: "POST",
            body: JSON.stringify({
              userId: loginForm.mobile,
              newPassword: loginForm.pin,
            }),
          });
        } else {
          showErrorAlert("PINs do not match!");
          return;
        }
      }
      await dispatch(
        loginAction({ mobile: loginForm.mobile, password: loginForm.pin })
      );
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      showErrorAlert(error.message);
    }
  }

  const renderInfoText = {
    1: "Please enter your Mobile Number",
    2: "Please enter PIN",
  };
  const renderStep = {
    1: (
      <Step1
        loginForm={loginForm}
        handleChange={handleChange}
        handleNext={handleNext}
      />
    ),
    2: (
      <PinComponent
        formInput={loginForm}
        confirmPin={firstLogin}
        buttonText="Login"
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    ),
  };

  return (
    <div className="row w-100 justify-content-center align-items-center">
      <div className="card col-xxl-4 col-xl-5 col-lg-6 col-md-7 col-sm-12 p-4 p-xl-5">
        <h2 className="card-title">Log In</h2>
        <span className="info-text">{renderInfoText[currentStep]}</span>
        {renderStep[currentStep]}
        {currentStep > 1 && (
          <button
          className="btn back-btn align-self-center cursor-pointer"
            onClick={() =>
              setCurrentStep((prevCurrentStep) => prevCurrentStep - 1)
            }
          >
            Back
          </button>
        )}
      </div>
    </div>
  );
}

// Step1 Component
function Step1({ loginForm, handleChange, handleNext }) {
  return (
    <form className="d-flex flex-column gap-4 py-3" onSubmit={handleNext}>
      <label className="form-label">
        Mobile
        <input
          type="text"
          className="form-control"
          name="mobile"
          minLength={10}
          maxLength={10}
          autoFocus
          value={loginForm.mobile}
          onChange={(event) => handleChange("mobile", event.target.value)}
        />
      </label>
      <button className="button login-button" disabled={!loginForm.mobile}>
        Next
      </button>
    </form>
  );
}

// Step2 Component
export function PinComponent({
  formInput,
  confirmPin,
  buttonText,
  handleChange,
  handleSubmit,
}) {
  const buttonActive = confirmPin
    ? formInput.pin.length === 4 && formInput.confirmPin.length === 4
    : formInput.pin.length === 4;
  const inputStyle = {
    border: "0.8px solid var(--clr-border-primary)",
    borderRadius: "8px",
    margin: "8px",
  };
  const inputFocusStyle = {
    border: "0.8px solid var(--clr-border-input-focus)",
    boxShadow: "0 0 0 .25rem rgba(13, 110, 253, .25)",
  };
  return (
    <form className="d-flex flex-column gap-3 py-3" onSubmit={handleSubmit}>
      <div className="form-label d-flex flex-row align-items-center justify-content-between">
        <span className="w-25">Enter PIN</span>
        <PinInput
          length={4}
          initialValue={formInput.pin}
          secretDelay={400}
          style={{display: "flex", flexWrap: "no-wrap"}}
          inputStyle={inputStyle}
          inputFocusStyle={inputFocusStyle}
          onChange={(value) => handleChange("pin", value)}
          focus
        />
      </div>
      {confirmPin && (
        <div className="form-label d-flex flex-row align-items-center justify-content-between">
          <span className="w-25">Confirm PIN</span>
          <PinInput
            length={4}
            initialValue={formInput.confirmPin}
            secretDelay={400}
            inputStyle={inputStyle}
            inputFocusStyle={inputFocusStyle}
            onChange={(value) => handleChange("confirmPin", value)}
          />
        </div>
      )}
      <button
        className="button login-button"
        disabled={!buttonActive}
      >
        {buttonText}
      </button>
    </form>
  );
}

export default Login;
