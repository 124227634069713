import React, { useState, useEffect } from "react";
import "./messageList.css";
import { useNavigate } from "react-router-dom";
import MessageFilters from "../../components/Filters/Filters";
import MessageTable from "../../components/MessageTable/MessageTable";
import { getMessageActions } from "../../constants/MessageConstants";
import { useModal } from "../../context/ModalContext";
import { useAlert } from "../../context/AlertContext";
import { useSelector, useDispatch } from "react-redux";
import { messageSelector, errorSelector } from "../../redux/messages/selectors";
import PageHeadingWithOptions from "../../components/PageHeadingWithOptions/PageHeadingWithOptions";

const MessageList = () => {
  // state from redux
  const messages = useSelector(messageSelector);
  const error = useSelector(errorSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showRedModal, showBlueModal } = useModal();
  const { showSuccessAlert, showErrorAlert } = useAlert();

  const [messagesList, setMessagesList] = useState([]);
  const [areAllMessagesSelected, setAreAllMessagesSelected] = useState(false);
  const [messageFilters, setMessageFilters] = useState({
    isVisible: false,
    nameFilter: "",
    statusFilter: "",
  });

  const isFilterActive = messageFilters.nameFilter || messageFilters.statusFilter;
  const messageActions = getMessageActions(
    showRedModal,
    showBlueModal,
    showSuccessAlert,
    showErrorAlert,
    messagesList,
    navigate,
    dispatch
  );

  useEffect(() => {
    if (messages && messages.length > 0) {
      setMessagesList(
        messages.map((message) => ({ ...message, isSelected: false }))
      );
    }
  }, [messages]);

  useEffect(() => {
    if (error) {
      console.error(error);
      showErrorAlert(error);
    }
  }, [error, showErrorAlert]);

  useEffect(() => {
    if (messagesList.length > 0) {
      setAreAllMessagesSelected(
        messagesList.every((message) => message.isSelected)
      );
    }
  }, [messagesList]);

  // function returning filtered messages list according to input in nameFilter
  function filteredMessagesList() {
    let filteredMessages = messagesList;
    if (messageFilters.nameFilter) {
      filteredMessages = filteredMessages.filter((message) =>
        message.name
          .toLowerCase()
          .includes(messageFilters.nameFilter.toLowerCase())
      );
    }
    if (messageFilters.statusFilter) {
      filteredMessages = filteredMessages.filter(
        (message) => message.status === messageFilters.statusFilter
      );
    }
    return filteredMessages;
  }

  // function for handling the change of single message selection checkbox
  function handleMessageSelectionChange(event, id) {
    setMessagesList((prevMessagesList) =>
      prevMessagesList.map((message) =>
        message.id === id
          ? { ...message, isSelected: event.target.checked }
          : message
      )
    );
  }

  // function for handling the change of all messages selection checkbox
  function handleAllMessagesSelectionChange(event) {
    setAreAllMessagesSelected(event.target.checked);
    setMessagesList((prevMessagesList) =>
      prevMessagesList.map((message) => ({
        ...message,
        isSelected: event.target.checked,
      }))
    );
  }

  // // function that handles change in filters
  function handleFilterChange(event) {
    setMessageFilters((prevMessageFilters) => ({
      ...prevMessageFilters,
      [event.target.name]: event.target.value,
    }));
  }

  // function for clearing applied filters
  function clearFilter() {
    setMessageFilters({ isVisible: false, nameFilter: "", statusFilter: "" });
  }

  // function to toggle filter visibility
  function toggleFilterVisibility() {
    setMessageFilters((prevMessageFilters) => ({
      ...prevMessageFilters,
      isVisible: !prevMessageFilters.isVisible,
    }));
  }

  return (
    <main id="main" className="main">
      <PageHeadingWithOptions
        pageHeading="Message Templates / Message List"
        actions={messageActions}
        actionsTooltipText="Actions on selected messages"
        filtersTooltipText="Filters"
        toggleFilterVisibility={toggleFilterVisibility}
        isFilterActive={isFilterActive}
        isFilterVisible={messageFilters.isVisible}
        filterComponent={
          <MessageFilters
            filters={messageFilters}
            handleFilterChange={handleFilterChange}
            clearFilter={clearFilter}
            nameFilterPlaceholder="Enter Message Name"
            statusOptions={[
              { status: "active", displayValue: "Active" },
              { status: "inactive", displayValue: "Inactive" },
            ]}
            showCloseButton={true}
          />
        }
      />

      <MessageTable
        messageList={filteredMessagesList()}
        handleMessageSelectionChange={handleMessageSelectionChange}
        handleAllMessagesSelectionChange={handleAllMessagesSelectionChange}
        areAllMessagesSelected={areAllMessagesSelected}
      />
    </main>
  );
};

export default MessageList;
