import { createContext, useContext, useEffect, useState } from "react";
import { client, xml } from "@xmpp/client";
import { useSelector } from "react-redux";
import {
  isLoggedInSelector,
  currentUserSelector,
} from "../redux/login/selectors";
import { myEventsSelector } from "../redux/myEvents/selectors";

let xmpp = null;

// function to connect to xmpp service
export const xmppConnect = (username, password, domain, service) => {
  xmpp = client({
    username,
    password,
    domain,
    service,
  });
  xmpp.start();
  console.log("chat server connected");
};

// function to disconnect to xmpp service
export const xmppDisconnect = () => {
  xmpp.send(xml("presence", { type: "unavailable" }));
  xmpp.stop();
  console.log("chat server disconnected");
};

// context for xmpp service
const XmppServiceContext = createContext();

// custom hook to use xmpp service
export const useXmppService = () => useContext(XmppServiceContext);

// xmpp service provider component
export const XmppServiceProvider = function ({ children }) {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const user = useSelector(currentUserSelector);
  const myEvents = useSelector(myEventsSelector);
  const [isOnline, setIsOnline] = useState(false);
  const [messages, setMessages] = useState({});

  function sendMessage(to, messageText, type) {
    const msg = xml("message", { type, to }, xml("body", {}, messageText));
    xmpp.send(msg);

    if (type === "chat") {
      setMessages((prevMessages) => {
        const message = { direction: "outgoing", content: messageText };
        let newMessages;
        if (prevMessages[to]) {
          newMessages = [...prevMessages[to], message];
        } else {
          newMessages = [message];
        }
        return { ...prevMessages, [to]: newMessages };
      });
    }
  }

  // function to join MUC with nickname of user's name
  function joinMuc(mucJid) {
    // Send a presence stanza to join the MUC
    xmpp.send(
      xml(
        "presence",
        { to: `${mucJid}/${user.mobile}`, from: user.jid },
        xml("x", { xmlns: "jabber:x:conference" })
      )
    );
    console.log(`Joined MUC: ${mucJid}`);
  }

  useEffect(() => {
    if (isLoggedIn && user) {
      xmppConnect(
        user.jid,
        user.mobile,
        process.env.REACT_APP_XMPP_DOMAIN,
        process.env.REACT_APP_XMPP_SERVICE
      );

      // sending presence
      xmpp.on("online", async (address) => {
        xmpp.reconnect.delay = 25000;
        await xmpp.send(xml("presence"));
        setIsOnline(xmpp.status === "online");
        console.log("online");
      });

      // logging error
      xmpp.on("error", (err) => console.error(err));

      // Listening for incoming messages
      xmpp.on("stanza", async (stanza) => {
        if (stanza.is("message") && stanza.attrs.type === "chat") {
          const from = stanza.attrs.from.split("/")[0];
          const body = stanza.getChildText("body");

          // Update messages state
          setMessages((prevMessages) => {
            const message = { direction: "incoming", content: body };
            let newMessages;
            if (prevMessages[from]) {
              newMessages = [...prevMessages[from], message];
            } else {
              newMessages = [message];
            }
            return { ...prevMessages, [from]: newMessages };
          });
        } else if (stanza.is("message") && stanza.attrs.type === "groupchat") {
          const jidPart = stanza.attrs.from.split("/");
          const [room, nickname] = jidPart;
          const body = stanza.getChildText("body");
          if (room && nickname) {
            setMessages((prevMessages) => {
              const message = {
                direction: nickname === user.mobile ? "outgoing" : "incoming",
                content: body,
                user: { name: nickname },
              };
              let newMessages;
              if (prevMessages[room]) {
                newMessages = [...prevMessages[room], message];
              } else {
                newMessages = [message];
              }
              return { ...prevMessages, [room]: newMessages };
            });
          }
        }
      });

      xmpp.on("offline", () => {
        console.log("offline");
        setIsOnline(xmpp.status === "online");
      });
    }
    return xmppDisconnect;
  }, []);

  // sending presence to groups
  useEffect(() => {
    if (isOnline && myEvents && myEvents.length > 0) {
      myEvents.forEach((event) => joinMuc(event.jid));
    }
  }, [myEvents, isOnline]);

  return (
    <XmppServiceContext.Provider value={{ messages, sendMessage }}>
      {children}
    </XmppServiceContext.Provider>
  );
};
