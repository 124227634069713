import React from "react";
import "./editUserForm.css"

const EditUserForm = ({
  userDetails,
  handleInputChange,
  updateUser,
  handleCloseClick,
}) => {
  return (
    <div className="card col-sm-12 col-md-8 col-xl-6">
      <div className="card-body d-flex flex-column gap-1">
        <h5 className="card-title">Edit User</h5>
        <label className="form-label">
          Name
          <input
            type="text"
            className="form-control"
            name="name"
            value={userDetails.name}
            onChange={handleInputChange}
          />
        </label>
        <label className="form-label" id="user-email">
          Mobile Number
          <input
            type="number"
            className="form-control"
            name="mobile"
            value={userDetails.mobile}
            onChange={handleInputChange}
          />
        </label>
      </div>
      <div className="modal-buttons">
        <button className="button primary-button rounded" onClick={updateUser} disabled={!(userDetails.name && userDetails.mobile)}>
          Submit
        </button>
        <button
          className="button secondary-button rounded"
          onClick={handleCloseClick}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default EditUserForm;
