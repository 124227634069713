import "./dragDropFileUpload.css";
import React from "react";
import { useAlert } from "../../context/AlertContext";
import { allowedFileTypes, allowedMimeTypes } from "../../utils/commonUtils";
import uploadCloud from "../../assets/svgs/uploadCloud.svg";
import { useSelector } from "react-redux";
import { loadingSelector } from "../../redux/users/selector";

const DragDropFileUpload = (props) => {
  const { showErrorAlert } = useAlert();
  const uploadFileLoading = useSelector(loadingSelector);

  // functionality for when user drops the file in the drag and drop element
  function drop(e) {
    e.preventDefault();
    props.fileInputElement.current.files = e.dataTransfer.files;
    fileUploadFunctionality();
  }

  function fileUploadFunctionality() {
    const files = props.fileInputElement.current.files;
    const isAllowedMimeType = Array.from(files).every((file) =>
      allowedMimeTypes.includes(file.type)
    );
    if (isAllowedMimeType) {
      if (files.length === 1) {
        props.handleFileUpload(files);
      }
    } else {
      props.fileInputElement.current.files = null;
      showErrorAlert(
        `File type not supported. Please upload only ${allowedFileTypes} files!`
      );
    }
  }

  return (
    <div id="add-multiple-user-form" className="d-flex flex-column flex-grow gap-3 align-items-center justify-content-center w-100">
      <input
        id="dropzone-file"
        ref={props.fileInputElement}
        type="file"
        className="visually-hidden"
        accept={allowedMimeTypes}
        onChange={fileUploadFunctionality}
        disabled={uploadFileLoading}
      />
      <label
        onDrop={drop}
        onDragOver={(e) => e.preventDefault()}
        htmlFor="dropzone-file"
        className="fileUpload d-flex flex-column align-items-center justify-content-center rounded"
      >
        <img src={uploadCloud}></img>
        <p className="m-1">Drag and drop to upload file</p>
        <p className="m-1" style={{ fontSize: "0.9rem" }}>Allowed Files: {allowedFileTypes}</p>
      </label>
      <button
        className="button primary-button rounded w-100"
        onClick={() => props.fileInputElement?.current.click()}
      >
        Browse File
      </button>
    </div>
  );
};

export default DragDropFileUpload;
