import { createSlice } from "@reduxjs/toolkit";
import { getEvents, createEvent, updateEvent, deleteEvents } from "./actions";

const initialState = {
  error: null,
  events: [],
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvents.fulfilled, (state, action) => {
      state.error = null;
      state.events = action.payload.data;
    });
    builder.addCase(getEvents.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(createEvent.rejected, (_, action) => {
      throw new Error(action.payload);
    });
    builder.addCase(updateEvent.rejected, (_, action) => {
      throw new Error(action.payload);
    });
    builder.addCase(deleteEvents.rejected, (_, action) => {
      throw new Error(action.payload);
    });
  },
});

export default eventsSlice.reducer;
