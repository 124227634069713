import React, { useEffect } from "react";
import "./dashboard.css";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "../../context/AlertContext";
import Card from "../../components/Card/Card";
// import DashboardCalendar from "../../components/DashboardCalendar/DashboardCalendar";
import {
  dashboardStatsSelector,
  errorSelector,
} from "../../redux/dashboardStats/selectors";
import { getDashboardStats } from "../../redux/dashboardStats/actions";
import { isNullish } from "../../utils/commonUtils";
import PageHeadingWithOptions from "../../components/PageHeadingWithOptions/PageHeadingWithOptions";

const Dashboard = () => {
  // state from redux
  const dashboardStats = useSelector(dashboardStatsSelector);
  const error = useSelector(errorSelector);

  const dispatch = useDispatch();
  const { showErrorAlert } = useAlert();
  const usersCardValue =
    !isNullish(dashboardStats.totalActiveUsers) &&
    !isNullish(dashboardStats.totalUsers) &&
    `${dashboardStats.totalActiveUsers}/${dashboardStats.totalUsers}`;

  useEffect(() => {
    dispatch(getDashboardStats());
  }, [dispatch, showErrorAlert]);

  useEffect(() => {
    if (error) {
      showErrorAlert(error.message)
    }
  }, [error, showErrorAlert]);

  return (
    <main id="main" className="main">
      <PageHeadingWithOptions pageHeading="Dashboard" />
      <div className="row">
        <div className="col-md-4 col-xxl-3" id="events-card">
          <Card
            heading="Total Events"
            icon={<i className="bi bi-calendar-check-fill"></i>}
            value={dashboardStats.totalEvents}
          />
        </div>
        <div className="col-md-4 col-xxl-3" id="users-card">
          <Card
            heading="Active Users/Total Users"
            icon={<i className="bi bi-people-fill"></i>}
            value={usersCardValue}
          />
        </div>
        {/* <div className="col-md-8 col-xxl-6">
          <DashboardCalendar />
        </div> */}
      </div>
    </main>
  );
};

export default Dashboard;
