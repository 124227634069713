import "./eventForm.css";
import DatePicker from "react-date-picker";
import React from "react";
import Icon from "../Icon/Icon";
import SelectedItemTile from "../SelectedItemTile/SelectedItemTile";
import LineWithText from "../LineWithText/LineWithText";

const EventForm = ({
  eventForm,
  handleInputChange,
  handleSubmit,
  handleResetForm,
  selectedMessages,
  handleMessageUnSelection,
  setEventForm,
}) => {
  return (
    <div id="event-form" className="card col-sm-12 col-md-8 col-xl-6">
      <div className="card-body d-flex flex-column gap-1">
        <h5 className="card-title">Event Form</h5>
        <label className="form-label">
          Name
          <input
            type="text"
            className="form-control"
            name="name"
            value={eventForm.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
          />
        </label>

        <label className="form-label">
          Start Date
          <DatePicker
            className="date-picker"
            clearIcon={null}
            next2Label={null}
            prev2Label={null}
            calendarIcon={
              <Icon>
                <i className="bi bi-calendar-fill"></i>
              </Icon>
            }
            name="startDate"
            value={eventForm.startDate}
            onChange={(value) =>
              setEventForm((prevEventForm) => ({
                ...prevEventForm,
                startDate: value,
                endDate:
                  new Date(value) > new Date(prevEventForm.endDate)
                    ? ""
                    : prevEventForm.endDate,
              }))
            }
          />
        </label>

        <label className="form-label">
          End Date
          <DatePicker
            className="date-picker"
            clearIcon={null}
            next2Label={null}
            prev2Label={null}
            calendarIcon={
              <Icon>
                <i className="bi bi-calendar-fill"></i>
              </Icon>
            }
            name="endDate"
            value={eventForm.endDate}
            onChange={(value) => handleInputChange("endDate", value)}
            disabled={!eventForm.startDate}
            minDate={eventForm.startDate}
          />
        </label>

        <label>
          Description
          <textarea
            className="form-control"
            name="description"
            value={eventForm.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
          />
        </label>

        {selectedMessages && selectedMessages.length ? (
          <>
            <LineWithText text="Messages Attached" />
            {selectedMessages.map((message) => (
              <SelectedItemTile
                key={message.id}
                item={message}
                handleItemUnSelection={handleMessageUnSelection}
              />
            ))}
          </>
        ) : null}

        <div className="d-flex justify-content-evenly mt-3">
          <button
            className="button primary-button rounded"
            disabled={Object.values(eventForm).some((field) => !field)}
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="button secondary-button rounded"
            disabled={Object.values(eventForm).every((field) => !field)}
            onClick={handleResetForm}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventForm;
