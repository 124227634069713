import React, { useState, useEffect, useCallback } from "react";
import "./editEvent.css"
import { useLocation } from "react-router-dom";
import AddEvent from "../AddEvent/AddEvent";
import { updateEvent, getEventById } from "../../redux/events/actions";
import { useDispatch } from "react-redux";
import { useAlert } from "../../context/AlertContext";
import eventsFormatterForUI from "../../formatters/eventsFormatter";

const EditEvent = () => {
  const dispatch = useDispatch();
  const { showErrorAlert } = useAlert();
  const location = useLocation();
  const props = location && location.state;
  const [eventInfo, setEventInfo] = useState();

  const getEventInfo = useCallback(async () => {
    try {
      const eventData = await dispatch(
        getEventById({ eventId: props.event.id })
      ).unwrap();
      setEventInfo(eventsFormatterForUI(eventData));
    } catch (error) {
      console.error(error);
      showErrorAlert(error.message);
    }
  }, [dispatch, showErrorAlert, props.event.id]);

  useEffect(() => {
    getEventInfo();
  }, [getEventInfo]);

  return <AddEvent eventInfo={eventInfo} updateEvent={updateEvent} heading="Edit Event" successMessage="Event Edited Successfully"/>;
};

export default EditEvent;
