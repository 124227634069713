import "./eventsDropdown.css";
import React, { useState } from "react";

/**
 * 
 * @param {Array} itemList - elements in item list should have id, name properties
 * @param {string} selectedItemId - id of the selected item
 * @param {Function} handleItemSelection - function to handle selection of item in dropdown
 * @param {function} setShowEventsDropdown - setter function to set state of visibility of dropdown
 * @param {string} searchPlaceHolder - placeholder for search input
 * @param {string} listHeading - heading for list
 * @param {Object} style - style object to be applied on container of this component.
 */
const DropdownWithSearch = ({ itemList, selectedItemId, handleItemSelection, setShowItemsDropdown, searchPlaceHolder, listHeading,  style }) => {
  const [nameFilter, setNameFilter] = useState("");

  // function for handling the change of name Filter
  function handleFilterChange(e) {
    setNameFilter(e.target.value);
  }

  // function returning filtered list according to input in nameFilter
  function filteredList() {
    return itemList.filter((item) =>
      item.name?.toLowerCase().includes(nameFilter.toLowerCase())
    );
  }

  return (
    <div className="events-dropdown thin-scrollbar rounded border border-1" style={style}>
      <div className="events-filter d-flex flex-row align-items-center">
        <i className="bi bi-filter" style={{ fontSize: "1.4rem" }}></i>
        <input
          type="text"
          className="form-control events-filter-input border-0 ms-1"
          placeholder={searchPlaceHolder}
          value={nameFilter}
          onChange={handleFilterChange}
          autoFocus
        />
      </div>
      <table className="table events-table">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>{listHeading}</th>
          </tr>
        </thead>
        <tbody>
          {filteredList().map((item, index) => (
            <tr
              key={item.id}
              onClick={() => {
                handleItemSelection(item.id);
                setShowItemsDropdown(false);
              }}
              className={`cursor-pointer ${
                selectedItemId === item.id ? "active" : ""
              }`}
            >
              <td>{index + 1}</td>
              <td>{item.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DropdownWithSearch;
