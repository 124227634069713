import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestToServer, toUseDemoData } from "../../utils/commonUtils";
import { messages } from "../../demo-data/messages-demo-data";
import { messageFormatter } from "../../formatters/messageFormatter";

export const getMessages = createAsyncThunk(
  "messages/getMessages",
  async (filterObject) => {
    if (toUseDemoData()) {
      return messages;
    } else {
      const queryParams = new URLSearchParams(filterObject).toString();
      const messageAPIData = await requestToServer(`/messages?${queryParams}`);
      return messageAPIData.map((message) => messageFormatter(message));
    }
  }
);

export const createMessage = createAsyncThunk(
  "messages/createMessage",
  async (messageInfo) => {
    if (toUseDemoData()) {
      return null;
    } else {
      const messageAPIData = await requestToServer("/messages", {
        method: "POST",
        body: JSON.stringify(messageInfo),
      });
      return { data: messageAPIData };
    }
  }
);

export const updateMessage = createAsyncThunk(
  "messages/updateMessage",
  async (messageInfo) => {
    if (toUseDemoData()) {
      return null;
    } else {
      if (messageInfo.id) {
        const messageAPIData = await requestToServer(
          `/messages/${messageInfo.id}`,
          { method: "PATCH", body: JSON.stringify(messageInfo) }
        );
        return { data: messageAPIData };
      } else {
        throw new Error("Couldn't find message to update!");
      }
    }
  }
);

export const deleteMessage = createAsyncThunk(
  "messages/deleteMessage",
  async (messageInfo) => {
    if (toUseDemoData()) {
      return null;
    } else {
      if (messageInfo.id) {
        const messageAPIData = await requestToServer(
          `/messages/${messageInfo.id}`,
          {
            method: "DELETE",
          }
        );
        return { data: messageAPIData };
      } else {
        throw new Error("Couldn't find message to delete!");
      }
    }
  }
);
