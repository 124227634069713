import { createSlice } from "@reduxjs/toolkit";
import { loginAction, refreshToken } from "./actions";

// getting user's info from session storage
const loggedInUser = sessionStorage.getItem("user");
export const loginSlice = createSlice({
  name: "login",
  initialState: loggedInUser
    ? { isLoggedIn: true, user: JSON.parse(loggedInUser) }
    : { isLoggedIn: false, user: null },
  reducers: {
    logout(state) {
      sessionStorage.removeItem("user");
      state.isLoggedIn = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAction.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.data;
    });
    builder.addCase(loginAction.rejected, (_, action) => {
      throw new Error(action.payload);
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      const newUserDetails = {...state.user, ...action.payload}
      sessionStorage.setItem("user", JSON.stringify(newUserDetails))
      return {
        isLoggedIn: true,
        user: newUserDetails
      }
    })
    builder.addCase(refreshToken.rejected, (state) => {
      sessionStorage.removeItem("user");
      state.isLoggedIn = false;
      state.user = null;
    })
  },
});

export const { logout } = loginSlice.actions;
export default loginSlice.reducer;
