import React, { useEffect, useState } from "react";
import "./addEvent.css";
import EventForm from "../../components/EventForm/EventForm";
import { useAlert } from "../../context/AlertContext";
import AttachMessagesComponent from "../../components/AttachMessagesComponent/AttachMessagesComponent";
import { useDispatch, useSelector } from "react-redux";
import { createEvent, getEvents } from "../../redux/events/actions";
import { messageSelector, errorSelector } from "../../redux/messages/selectors";
import { eventsFormatterForAPI } from "../../formatters/eventsFormatter";
import PageHeadingWithOptions from "../../components/PageHeadingWithOptions/PageHeadingWithOptions";

const AddEvent = ({ eventInfo, heading, successMessage, updateEvent }) => {
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const dispatch = useDispatch();
  const messages = useSelector(messageSelector);
  const error = useSelector(errorSelector);

  const [eventForm, setEventForm] = useState({
    name: "",
    startDate: null,
    endDate: null,
    description: "",
  });

  const [messagesList, setMessagesList] = useState([]);
  const [areAllMessagesSelected, setAreAllMessagesSelected] = useState(false);

  const [messageFilters, setMessageFilters] = useState({
    nameFilter: "",
  });

  useEffect(() => {
    if (messages && messages.length > 0) {
      setMessagesList(
        messages.map((message) => ({
          ...message,
          isSelected: eventInfo?.messageIds.includes(message.id) || false,
        }))
      );
    }
    setEventForm({
      name: eventInfo?.name || "",
      startDate: eventInfo?.startDate || null,
      endDate: eventInfo?.endDate || null,
      description: eventInfo?.description || "",
    });
  }, [messages, eventInfo]);

  useEffect(() => {
    if (error) {
      showErrorAlert(error);
    }
  }, [error, showErrorAlert]);

  useEffect(() => {
    setAreAllMessagesSelected(
      messagesList.every((message) => message.isSelected)
    );
  }, [messagesList]);

  // create event functionality
  async function handleSubmit() {
    const requestBody = {
      ...eventForm,
      id: eventInfo?.id,
      messageIds: messagesList
        .filter((message) => message.isSelected)
        .map((message) => message.id),
    };
    const formattedRequestBody = eventsFormatterForAPI(requestBody);

    try {
      await dispatch(
        updateEvent
          ? updateEvent(formattedRequestBody)
          : createEvent(formattedRequestBody)
      ).unwrap();
      dispatch(getEvents());
      showSuccessAlert(
        successMessage ? successMessage : "Event Created Successfully!"
      );
    } catch (error) {
      console.error(error);
      showErrorAlert(error.message);
    }
  }

  // functionality for resetting the form
  function handleResetForm() {
    setEventForm({
      name: "",
      startDate: null,
      endDate: null,
      description: "",
    });
  }

  // functionality for updating the state when user changes input fields
  function handleInputChange(key, value) {
    setEventForm({ ...eventForm, [key]: value });
  }

  // function to handle change in filters of message
  function handleFilterChange(event) {
    const { name, value } = event.target;
    setMessageFilters((prevMessageFilters) => ({
      ...prevMessageFilters,
      [name]: value,
    }));
  }

  // function returning filtered messagesList according to input in nameFilter
  function filteredMessagesList() {
    return messagesList.filter((message) =>
      message.name
        .toLowerCase()
        .includes(messageFilters.nameFilter.toLowerCase())
    );
  }

  // function for handling the change of single message selection checkbox
  function handleMessageSelectionChange(value, id) {
    setMessagesList((prevMessagesList) =>
      prevMessagesList.map((message) =>
        message.id === id ? { ...message, isSelected: value } : message
      )
    );
  }

  function handleMessageUnSelection(id) {
    setMessagesList((prevMessagesList) =>
      prevMessagesList.map((message) =>
        message.id === id ? { ...message, isSelected: false } : message
      )
    );
  }

  // function for handling the change of all messages selection checkbox
  function handleAllMessagesSelectionChange(e) {
    setAreAllMessagesSelected(e.target.checked);
    setMessagesList((prevMessagesList) =>
      prevMessagesList.map((message) => ({
        ...message,
        isSelected: e.target.checked,
      }))
    );
  }

  return (
    <main id="main" className="main">
      <PageHeadingWithOptions pageHeading={`Events / ${heading ? heading : "Add Event"}`} />
      <div className="row justify-content-center gap-md-4 gap-xl-5 align-items-start">
        <EventForm
          eventForm={eventForm}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          handleResetForm={handleResetForm}
          selectedMessages={!updateEvent && messagesList.filter(
            (message) => message.isSelected
          )}
          handleMessageUnSelection={handleMessageUnSelection}
          setEventForm={setEventForm}
        />
        {!updateEvent && (
          <AttachMessagesComponent
            messagesList={filteredMessagesList()}
            messageFilters={messageFilters}
            handleFilterChange={handleFilterChange}
            areAllMessagesSelected={areAllMessagesSelected}
            handleAllMessagesSelectionChange={handleAllMessagesSelectionChange}
            handleMessageSelectionChange={handleMessageSelectionChange}
          />
        )}
      </div>
    </main>
  );
};

export default AddEvent;
