import React, { useState, useEffect } from "react";
import "./messageModal.css";
import MessageTable from "../MessageTable/MessageTable";
import { messageSelector } from "../../redux/messages/selectors";
import { useSelector } from "react-redux";
import { useModal } from "../../context/ModalContext";
import { useXmppService } from "../../context/xmppContext";

const MessageModal = ({ selectedTab }) => {
  const { setModal } = useModal();
  const { sendMessage } = useXmppService();

  // getting all template messages
  const messages = useSelector(messageSelector);
  const [messageList, setMessageList] = useState(messages.map(message => ({...message, isSelected: false})));
  const [areAllMessagesSelected, setAreAllMessagesSelected] = useState(false);

  useEffect(() => {
    setAreAllMessagesSelected(
      messageList.every((message) => message.isSelected)
    );
  }, [messageList]);

  // function for handling the change of single message selection checkbox
  function handleMessageSelectionChange(e, id) {
    setMessageList((prevMessageList) =>
      prevMessageList.map((message) =>
        message.id === id
          ? { ...message, isSelected: e.target.checked }
          : message
      )
    );
  }

  // function for handling the change of all messages selection checkbox
  function handleAllMessagesSelectionChange(e) {
    setAreAllMessagesSelected(e.target.checked);
    setMessageList((prevMessageList) =>
      prevMessageList.map((message) => ({
        ...message,
        isSelected: e.target.checked,
      }))
    );
  }

  // function for sending template message
  function sendTemplateMessage() {
    messageList.filter(message => message.isSelected).forEach(message => {
      sendMessage(selectedTab.jid, message.content, "chat");
    })
  }

  return (
    <>
      <div className="message-modal thin-scrollbar">
        <MessageTable
          messageList={messageList}
          areAllMessagesSelected={areAllMessagesSelected}
          handleMessageSelectionChange={handleMessageSelectionChange}
          handleAllMessagesSelectionChange={handleAllMessagesSelectionChange}
          previewPrimaryButtonText="Send"
          selectedTab={selectedTab}
        />
      </div>
      <div className="modal-buttons">
        <button
          className="button rounded primary-button"
          onClick={() => {
            sendTemplateMessage()
            setModal({ isVisible: false });
          }}
        >
          Send
        </button>
        <button
          className="button secondary-button rounded"
          onClick={() => setModal({ isVisible: false })}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export default MessageModal;
