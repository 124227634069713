import "./modalComponent.css";
import React, { useEffect, useRef } from "react";
import useClickOutside from "../../utils/useClickOutside";
import { useModal } from "../../context/ModalContext";
import useDisableBodyScroll from "../../utils/useDisableBodyScroll";

const ModalComponent = () => {
  const { modal, setModal } = useModal();
  const modalRef = useRef(null);

  // closing the modal when clicked outside of it
  useClickOutside(modalRef, () => setModal({ isVisible: false }));
  useDisableBodyScroll(modal.isVisible);

  // functionality for closing modal when escape is pressed
  function handleKeydown(e) {
    if (e.keyCode === 27) {
      setModal({ isVisible: false });
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  });

  if (modal.isVisible) {
    return (
      <div className="modal-overlay">
        <div className="modal-content" ref={modalRef}>
          <h4 className="modal-heading">{modal.title}</h4>
          {/* showing custom component if passed otherwise showing default modal */}
          {modal.customComponent ? (
            modal.customComponent
          ) : (
            <>
              <span className="modal-text">{modal.modalText}</span>
              {modal.warningText && (
                <>
                  <span>You can't undo this action.</span>
                  <div className="modal-warning">
                    <div className="d-flex gap-2 align-items-center">
                      <i className="bi bi-exclamation-triangle-fill"></i>
                      <h6>Warning!!</h6>
                    </div>
                    <div>{modal.warningText}</div>
                  </div>
                </>
              )}

              <div className="modal-buttons">
                <button
                  className={`button rounded ${
                    modal.dangerAction ? "danger-button" : "primary-button"
                  }`}
                  onClick={() => {
                    modal.onClick();
                    setModal({ isVisible: false });
                  }}
                >
                  {modal.buttonText}
                </button>
                <button
                  className="button secondary-button rounded"
                  onClick={() => setModal({ isVisible: false })}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default ModalComponent;
