/**
 * Returns true if the two dates are in the same day, false otherwise.
 * @param {Date} a - The first date.
 * @param {Date} b - The second date.
 * @returns {boolean} True if the two dates are in the same day, false otherwise.
 */
export function isSameDay(a, b) {
  const startDate = new Date(a);
  const endDate = new Date(b);
  return (
    Math.abs(startDate.getTime() - endDate.getTime()) < 86400000 &&
    startDate.getDate() === endDate.getDate()
  );
}

/**
 * Returns a date in the format of "dd/mm/yyyy" required for UI.
 * @param {Date} a - The date to format.
 * @returns {string} The formatted date.
 */
export function dateInUIFormat(a) {
  const date = new Date(a);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

/**
 * Returns a date in the format of "yyyy-mm-dd" required for API.
 * @param {Date} a - The date to format.
 * @returns {string} The formatted date.
 */
export function dateInApiFormat(a) {
  const dateObject = new Date(a);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  return `${year}-${month}-${day}`;
}
