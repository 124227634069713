export default function statusFormatter(status) {
  switch (status) {
    case 0:
      return "inactive";
    case 1:
      return "active";
    default:
      return "deleted";
  }
}

export function statusFormatterForAPI(status) {
  switch(status) {
    case "inactive":
      return 0;
    case "active":
      return 1
  }
}
